// // src/App.js
// import React, { useState, useEffect } from 'react';
// import Layout from './components/Layout';
// import InspectionDashboard from './components/InspectionDashboard';
// import HistoryView from './components/HistoryView';
// import ReportsView from './components/ReportsView';
// import { trackPageView, trackEvent, trackError, initializeAnalytics } from './utils/analytics';

// // Analytics Configuration
// const GA4_MEASUREMENT_ID = 'G-B5NZJGWFEC';

// function App() {
//   const [currentPage, setCurrentPage] = useState('dashboard');
//   const [selectedInitialDate, setSelectedInitialDate] = useState(null);
//   const [analyticsInitialized, setAnalyticsInitialized] = useState(false);

//   // Initialize analytics
//   useEffect(() => {
//     const setupAnalytics = async () => {
//       // Load GA4 script
//       const script1 = document.createElement('script');
//       script1.async = true;
//       script1.src = `https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`;
      
//       const script2 = document.createElement('script');
//       script2.innerHTML = `
//         window.dataLayer = window.dataLayer || [];
//         function gtag(){dataLayer.push(arguments);}
//         gtag('js', new Date());
//       `;
      
//       document.head.appendChild(script1);
//       document.head.appendChild(script2);

//       // Wait for scripts to load
//       await new Promise(resolve => script1.onload = resolve);

//       // Initialize analytics
//       initializeAnalytics();
//       setAnalyticsInitialized(true);

//       // Track initial page view
//       trackPageView('dashboard', 'Dashboard');
//     };

//     setupAnalytics();

//     return () => {
//       const scripts = document.querySelectorAll('script[src*="googletagmanager.com"]');
//       scripts.forEach(script => script.remove());
//     };
//   }, []);

//   // Track page changes
//   useEffect(() => {
//     if (analyticsInitialized) {
//       const pageTitle = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);
//       trackPageView(currentPage, pageTitle);
      
//       // Track custom page change event
//       trackEvent('page_change', {
//         page_name: currentPage,
//         timestamp: new Date().toISOString()
//       });
//     }
//   }, [currentPage, analyticsInitialized]);

//   const handlePageChange = (page, date = null) => {
//     setCurrentPage(page);
    
//     if (date && analyticsInitialized) {
//       setSelectedInitialDate(date);
//       // Track date selection event
//       trackEvent('date_selected', {
//         page,
//         selected_date: date,
//         timestamp: new Date().toISOString()
//       });
//     } else {
//       setSelectedInitialDate(null);
//     }
//   };

//   // Track specific component views
//   const trackComponentView = (componentName, additionalData = {}) => {
//     if (analyticsInitialized) {
//       trackEvent('component_view', {
//         component_name: componentName,
//         ...additionalData,
//         timestamp: new Date().toISOString()
//       });
//     }
//   };

//   const renderPage = () => {
//     switch(currentPage) {
//       case 'historie':
//         trackComponentView('HistoryView', { 
//           initial_date: selectedInitialDate 
//         });
//         return <HistoryView initialDate={selectedInitialDate} />;
        
//       case 'reports':
//         trackComponentView('ReportsView');
//         return <ReportsView />;
        
//       default:
//         trackComponentView('InspectionDashboard');
//         return <InspectionDashboard 
//           onDefectClick={(date) => handlePageChange('historie', date)} 
//         />;
//     }
//   };

//   // Track error events
//   const handleError = (error) => {
//     if (analyticsInitialized) {
//       trackError(error, {
//         page: currentPage,
//         timestamp: new Date().toISOString()
//       });
//     }
//   };

//   return (
//     <Layout 
//       activePage={currentPage} 
//       onPageChange={(page) => handlePageChange(page)}
//       onError={handleError}
//     >
//       {renderPage()}
//     </Layout>
//   );
// }

// export default App;
import React, { useState, useEffect } from 'react';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import Layout from './components/Layout';
import LoginPage from './components/LoginPage';
import InspectionDashboard from './components/InspectionDashboard';
import HistoryView from './components/HistoryView';
import ReportsView from './components/ReportsView';
import { trackPageView, trackEvent, trackError, initializeAnalytics } from './utils/analytics';

// Analytics Configuration
const GA4_MEASUREMENT_ID = 'G-B5NZJGWFEC';

// Cognito Configuration
const poolData = {
  UserPoolId: 'us-east-1_2Ys8CjpHH',
  ClientId: '2dsf1amtk7872jhgj985f4qhkb'
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentPage, setCurrentPage] = useState('dashboard');
  const [selectedInitialDate, setSelectedInitialDate] = useState(null);
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Initialize auth state
  useEffect(() => {
    checkAuthState();
  }, []);

  const checkAuthState = () => {
    try {
      const userPool = new CognitoUserPool(poolData);
      const currentUser = userPool.getCurrentUser();

      if (!currentUser) {
        setIsLoading(false);
        setIsAuthenticated(false);
        return;
      }

      currentUser.getSession((err, session) => {
        if (err || !session?.isValid()) {
          setIsAuthenticated(false);
          setUser(null);
          setIsLoading(false);
          return;
        }

        currentUser.getUserData((err, userData) => {
          if (!err && userData) {
            setUser({
              username: userData.Username || currentUser.getUsername()
            });
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            setUser(null);
          }
          setIsLoading(false);
        });
      });
    } catch (error) {
      console.error('Auth check error:', error);
      setIsAuthenticated(false);
      setUser(null);
      setIsLoading(false);
    }
  };

  const handleLogin = (username) => {
    setIsAuthenticated(true);
    setUser({ username });
    if (analyticsInitialized) {
      trackEvent('login_success', {
        timestamp: new Date().toISOString(),
        username
      });
    }
  };

  const handleLogout = () => {
    try {
      const userPool = new CognitoUserPool(poolData);
      const currentUser = userPool.getCurrentUser();
      
      if (currentUser) {
        currentUser.signOut();
      }
      
      // Clear all auth states
      setUser(null);
      setIsAuthenticated(false);
      setCurrentPage('dashboard');
      
      if (analyticsInitialized) {
        trackEvent('user_logout', {
          timestamp: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handlePageChange = (page, date = null) => {
    setCurrentPage(page);
    if (date && analyticsInitialized) {
      setSelectedInitialDate(date);
    } else {
      setSelectedInitialDate(null);
    }
  };

  const trackComponentView = (componentName, additionalData = {}) => {
    if (analyticsInitialized) {
      trackEvent('component_view', {
        component_name: componentName,
        ...additionalData,
        timestamp: new Date().toISOString()
      });
    }
  };

  const renderPage = () => {
    switch(currentPage) {
      case 'historie':
        trackComponentView('HistoryView', { initial_date: selectedInitialDate });
        return <HistoryView initialDate={selectedInitialDate} />;
        
      case 'reports':
        trackComponentView('ReportsView');
        return <ReportsView />;
        
      default:
        trackComponentView('InspectionDashboard');
        return <InspectionDashboard 
          onDefectClick={(date) => handlePageChange('historie', date)} 
        />;
    }
  };

  const handleError = (error) => {
    if (analyticsInitialized) {
      trackError(error, {
        page: currentPage,
        timestamp: new Date().toISOString()
      });
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#0F1216]">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-cyan-500"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <LoginPage
        onLoginSuccess={handleLogin}
        poolData={poolData}
      />
    );
  }

  return (
    <Layout 
      activePage={currentPage} 
      onPageChange={handlePageChange}
      onError={handleError}
      onLogout={handleLogout}
      user={user}
    >
      {renderPage()}
    </Layout>
  );
}


export default App;