import React from 'react';
import { Percent } from 'lucide-react';

const SemiCircleProgress = ({ value, color, label }) => {
  const rotation = (value / 100) * 180;
  
  return (
    <div className="flex flex-col items-center">
      <div className="relative w-24 h-12 overflow-hidden">
        {/* Background semi-circle */}
        <div className="absolute w-24 h-24 rounded-full border-4 border-slate-800 top-0" 
             style={{ clipPath: 'polygon(0 50%, 100% 50%, 100% 0, 0 0)' }} />
        
        {/* Progress semi-circle */}
        <div className="absolute w-24 h-24 rounded-full border-4 top-0 transition-transform duration-1000" 
             style={{ 
               borderColor: color,
               clipPath: 'polygon(0 50%, 100% 50%, 100% 0, 0 0)',
               transform: `rotate(${180 - rotation}deg)`,
               transformOrigin: '50% 50%'
             }} />
             
        {/* Center value */}
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-sm font-bold" style={{ color }}>{value}%</span>
        </div>
      </div>
      <span className="text-xs text-slate-400 mt-1">{label}</span>
    </div>
  );
};

const OEEMetrics = ({ quality, performance, availability }) => {
  return (
    <div className="col-span-1 bg-slate-900/60 backdrop-blur-sm border border-slate-800/60 rounded-lg p-4">
      <div className="flex items-center gap-2 mb-3">
        <Percent className="h-5 w-5 text-purple-400" />
        <h2 className="text-slate-200 font-semibold">OEE Components</h2>
      </div>
      
      <div className="flex justify-center items-center h-[calc(100%-2rem)]">
        <div className="inline-flex flex-wrap gap-8 justify-center items-center min-w-0">
          <SemiCircleProgress 
            value={quality} 
            color="#10b981" 
            label="Quality" 
          />
          <SemiCircleProgress 
            value={performance} 
            color="#eab308" 
            label="Performance" 
          />
          <SemiCircleProgress 
            value={availability} 
            color="#3b82f6" 
            label="Availability" 
          />
        </div>
      </div>
    </div>
  );
};

export default OEEMetrics;