// src/components/layout/PageContainer.jsx
import React from 'react';

const PageContainer = ({ children }) => {
  return (
    <div className="min-h-screen bg-slate-950 p-4">
      <div className="container mx-auto">
        {children}
      </div>
    </div>
  );
};

export default PageContainer;