import React, { useCallback,useState, useEffect, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import {
  Check, XCircle, Percent, BarChart3, ScaleArrowUp, ArrowDown,
  Calendar, BarChart2, ArrowUpDown, Camera, Scale, Activity, Timer, Clock, AlertTriangle,AlertCircle,CheckCircle,ChevronLeft,ChevronRight
} from 'lucide-react';
import { Button } from '../ui/button';
import AWS from 'aws-sdk';
import OEEMetrics from './OEEMetrics';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import PageContainer from '../Layout/PageContainer';
AWS.config.update({
  region: 'us-east-1',
  accessKeyId: 'AKIA3LET6GOC2WEU2DWR',
  secretAccessKey: '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',
  signatureVersion: 'v4',
});
const s3 = new AWS.S3();
const dynamoDB = new AWS.DynamoDB.DocumentClient();
// Initial uptime values
const INITIAL_UPTIME = {
  days: 24,
  hours: 10,
  minutes: 30
};
const INITIAL_START_DATE = new Date('2024-10-28T10:30:00');



const ProductionStatisticsModal = ({ isOpen, onClose, inspectionData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [showMonthPicker, setShowMonthPicker] = useState(false);
  const baseStats = useRef({ total: 0, good: 0, defects: 0 });
  const hasLoadedHistorical = useRef(false);
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [allTimeStats, setAllTimeStats] = useState({
    totalProduction: 0,
    goodItems: 0,
    defects: 0,
    lastUpdate: null
  });
  const [pickerYear, setPickerYear] = useState(new Date().getFullYear());


  const ShiftProgress = ({ 
    currentShift, 
    shiftProgress, 
    getShiftTimeRemaining, 
    onShiftChange 
  }) => {
    return (
      <div className="flex flex-col items-center h-full justify-center p-4">
        <div className="text-lg font-medium text-cyan-400 mb-4">
          {`Shift ${currentShift}`}
        </div>
        
        {/* Circular Progress */}
        <div className="relative w-32 h-32 xl:w-40 xl:h-40">
          <svg className="w-full h-full -rotate-90 transform">
            {/* Background circle */}
            <circle
              cx="50%"
              cy="50%"
              r="45%"
              className="stroke-slate-700/30"
              strokeWidth="8"
              fill="none"
            />
            
            {/* Progress circle */}
            <circle
              cx="50%"
              cy="50%"
              r="45%"
              className="stroke-cyan-400/60"
              strokeWidth="8"
              fill="none"
              strokeDasharray={283} // 2 * π * r
              strokeDashoffset={283 - (283 * shiftProgress) / 100}
              strokeLinecap="round"
              style={{
                filter: 'drop-shadow(0 0 6px rgba(34, 211, 238, 0.3))',
                transition: 'stroke-dashoffset 0.5s ease'
              }}
            />
          </svg>
          
          {/* Center text */}
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <span className="text-2xl xl:text-3xl font-bold text-cyan-400">
              {shiftProgress}%
            </span>
            <span className="text-xs xl:text-sm text-slate-400 mt-1">Complete</span>
          </div>
        </div>
  
        {/* Remaining Time */}
        <div className="text-center mt-4">
          <span className="text-xs xl:text-sm text-slate-400">
            {getShiftTimeRemaining(currentShift)}
          </span>
        </div>
      </div>
    );
  };
  

  // Keep all existing fetch functions and effects...
  const fetchMonthData = async (year, month) => {
    try {
      const monthStr = `${year}-${String(month + 1).padStart(2, '0')}`;
      const params = {
        TableName: 'saudican',
        FilterExpression: 'begins_with(last_update, :yearMonth)',
        ExpressionAttributeValues: {
          ':yearMonth': monthStr
        }
      };

      const result = await dynamoDB.scan(params).promise();
      if (result.Items) {
        const monthData = result.Items.reduce((acc, item) => {
          acc.total += item.total_count || 0;
          acc.good += item.good_count || 0;
          acc.defects += item.defect_count || 0;
          acc.lastUpdate = item.last_update;
          return acc;
        }, { total: 0, good: 0, defects: 0, lastUpdate: null });

        setMonthlyStats([{
          month: monthStr,
          total: monthData.total,
          good: monthData.good,
          defects: monthData.defects,
          efficiency: ((monthData.good / monthData.total) * 100).toFixed(1),
          avgDaily: Math.round(monthData.total / 30),
          lastUpdate: new Date(monthData.lastUpdate).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          })
        }]);
      }
    } catch (error) {
      console.error('Error fetching month data:', error);
    }
  };

  

  const fetchHistoricalData = async () => {
    if (hasLoadedHistorical.current) return;
    try {
      setIsLoading(true);
      const historicalParams = {
        TableName: 'saudican',
        FilterExpression: 'attribute_exists(last_update)'
      };

      const result = await dynamoDB.scan(historicalParams).promise();
      if (result.Items) {
        let totalProduction = 0;
        let totalGood = 0;
        let totalDefects = 0;

        result.Items.forEach(item => {
          if (!item.last_update) return;
          totalProduction += item.total_count || 0;
          totalGood += item.good_count || 0;
          totalDefects += item.defect_count || 0;
        });

        baseStats.current = {
          total: totalProduction,
          good: totalGood,
          defects: totalDefects
        };
        hasLoadedHistorical.current = true;
      }
    } catch (error) {
      console.error('Error fetching historical data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && !hasLoadedHistorical.current) {
      fetchHistoricalData();
    }
  }, [isOpen]);

  useEffect(() => {
    const updateStats = () => {
      const total = baseStats.current.total + inspectionData.currentShift.totalInspected;
      const good = baseStats.current.good + inspectionData.currentShift.good;
      const defects = baseStats.current.defects + inspectionData.currentShift.defects.total;

      setAllTimeStats(prev => {
        if (prev.totalProduction !== total || prev.goodItems !== good || prev.defects !== defects) {
          return {
            totalProduction: total,
            goodItems: good,
            defects: defects,
            lastUpdate: new Date().toLocaleString()
          };
        }
        return prev;
      });
    };

    if (isOpen) {
      updateStats();
      const interval = setInterval(updateStats, 1000);
      return () => clearInterval(interval);
    }
  }, [inspectionData, isOpen]);

  const MonthPicker = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const isMonthDisabled = (year, month) => {
      return year > currentYear || (year === currentYear && month > currentMonth);
    };

    const getMonthButtonClass = (month) => {
      const isCurrentMonth = month === currentMonth && pickerYear === currentYear;
      const isDisabled = isMonthDisabled(pickerYear, month);

      return `p-2 text-sm rounded ${
        isCurrentMonth 
          ? 'bg-emerald-500/20 text-emerald-400 hover:bg-emerald-500/30'
          : isDisabled
            ? 'text-slate-600 cursor-not-allowed'
            : 'hover:bg-blue-500/20 text-slate-300 hover:text-white'
      }`;
    };

    return (
      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-slate-800 rounded-lg shadow-xl p-4 z-50 min-w-[320px]">
        <div className="flex items-center justify-between mb-4">
          <button
            onClick={() => setPickerYear(prev => prev - 1)}
            className="text-slate-400 hover:text-white"
            disabled={pickerYear <= 2020}
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          <span className="text-white font-medium">{pickerYear}</span>
          <button
            onClick={() => setPickerYear(prev => prev + 1)}
            className="text-slate-400 hover:text-white"
            disabled={pickerYear >= currentYear}
          >
            <ChevronRight className="h-5 w-5" />
          </button>
        </div>
        <div className="grid grid-cols-3 gap-2">
          {Array.from({ length: 12 }, (_, i) => (
            <button
              key={i}
              onClick={() => {
                if (!isMonthDisabled(pickerYear, i)) {
                  fetchMonthData(pickerYear, i);
                  setSelectedMonth(`${pickerYear}-${String(i + 1).padStart(2, '0')}`);
                  setShowMonthPicker(false);
                }
              }}
              disabled={isMonthDisabled(pickerYear, i)}
              className={getMonthButtonClass(i)}
            >
              {new Date(2024, i).toLocaleString('default', { month: 'short' })}
            </button>
          ))}
        </div>
      </div>
    );
  };


  // Rest of the component remains the same...
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent className="max-w-4xl bg-slate-900 text-slate-100 border-slate-800">
      <DialogHeader>
        <DialogTitle className="text-2xl font-bold text-slate-100 flex items-center gap-2">
          {/* <Calendar className="h-6 w-6 text-blue-400" /> */}
          Overall Production Statistics
        </DialogTitle>
      </DialogHeader>

      <div className="mt-4">
          <div className="grid grid-cols-3 gap-4 mb-6">
            <div className="bg-slate-800/50 rounded-lg p-4 flex flex-col">
              <span className="text-sm text-slate-400">All Time Production</span>
              <span className="text-2xl font-bold text-blue-400 mt-1">
                {allTimeStats.totalProduction.toLocaleString()}
              </span>
            </div>
            <div className="bg-slate-800/50 rounded-lg p-4 flex flex-col">
              <span className="text-sm text-slate-400">All Time Good Items</span>
              <span className="text-2xl font-bold text-emerald-400 mt-1">
                {allTimeStats.goodItems.toLocaleString()}
              </span>
            </div>
            <div className="bg-slate-800/50 rounded-lg p-4 flex flex-col">
              <span className="text-sm text-slate-400">All Time Defects</span>
              <span className="text-2xl font-bold text-rose-400 mt-1">
                {allTimeStats.defects.toLocaleString()}
              </span>
              <span className="text-xs text-slate-500 mt-1">
                Last Update: {allTimeStats.lastUpdate}
              </span>
            </div>
          </div>

          <div className="mb-4 relative mt-16">
            <div className="flex items-center justify-center gap-4">
              <h3 className="text-lg font-medium text-slate-200">Select Progress Per Month</h3>
              <Button
                variant="outline"
                size="sm"
                onClick={() => setShowMonthPicker(prev => !prev)}
                className="text-slate-300"
              >
                {selectedMonth || 'Select Month'}
                <Calendar className="ml-2 h-4 w-4" />
              </Button>
            </div>
            {showMonthPicker && <MonthPicker />}
          </div>

          {selectedMonth && monthlyStats.length > 0 && (
            <div className="rounded-lg overflow-hidden border border-slate-800">
              <table className="w-full">
                <thead>
                  <tr className="bg-slate-800/50">
                    <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">Month</th>
                    <th className="px-4 py-3 text-right text-sm font-medium text-slate-300">Last Update</th>
                    <th className="px-4 py-3 text-right text-sm font-medium text-slate-300">Total Parts</th>
                    <th className="px-4 py-3 text-right text-sm font-medium text-slate-300">Good Parts</th>
                    <th className="px-4 py-3 text-right text-sm font-medium text-slate-300">Defects</th>
                    <th className="px-4 py-3 text-right text-sm font-medium text-slate-300">Efficiency</th>
                    <th className="px-4 py-3 text-right text-sm font-medium text-slate-300">Daily Avg</th>
                  </tr>
                </thead>
                <tbody>
                  {monthlyStats.map((stat) => (
                    <tr key={stat.month} className="hover:bg-slate-800/30">
                      <td className="px-4 py-3 text-sm text-slate-300">
                        {new Date(stat.month + '-01').toLocaleString('default', { month: 'long', year: 'numeric' })}
                      </td>
                      <td className="px-4 py-3 text-right text-sm text-slate-400">{stat.lastUpdate}</td>
                      <td className="px-4 py-3 text-right text-sm text-slate-300">{stat.total.toLocaleString()}</td>
                      <td className="px-4 py-3 text-right text-sm text-emerald-400">{stat.good.toLocaleString()}</td>
                      <td className="px-4 py-3 text-right text-sm text-rose-400">{stat.defects.toLocaleString()}</td>
                      <td className="px-4 py-3 text-right text-sm">
                        <span className={`px-2 py-1 rounded-full text-xs ${
                          parseFloat(stat.efficiency) >= 95 
                            ? 'bg-emerald-500/10 text-emerald-400'
                            : parseFloat(stat.efficiency) >= 90
                              ? 'bg-yellow-500/10 text-yellow-400'
                              : 'bg-rose-500/10 text-rose-400'
                        }`}>
                          {stat.efficiency}%
                        </span>
                      </td>
                      <td className="px-4 py-3 text-right text-sm text-slate-300">{stat.avgDaily.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};




// Modify the StatCard component to handle button click
const StatCard = ({ title, value, icon, type, onClick, isButton = false, subtitle }) => {
  const getColor = () => {
    switch (type) {
      case 'success': return 'text-emerald-400';
      case 'error': return 'text-rose-400';
      case 'info': return 'text-cyan-400';
      case 'warning': return 'text-amber-400';
      default: return 'text-blue-400';
    }
  };

  const content = (
    <>
      <div>
        <p className="text-slate-400 text-sm mb-1">{title}</p>
        <h3 className={`text-2xl font-bold ${getColor()}`}>{value}</h3>
        {subtitle && <p className="text-xs text-slate-500 mt-1">{subtitle}</p>}
      </div>
      <div className={`${getColor()} opacity-80`}>{icon}</div>
    </>
  );

  if (isButton) {
    return (
      <button
        onClick={onClick}
        className="w-full bg-slate-900/60 rounded-lg p-4 backdrop-blur-sm border border-slate-800/60 hover:bg-slate-800/60 transition-colors text-left"
      >
        <div className="flex justify-between items-start">{content}</div>
      </button>
    );
  }

  return (
    <div className="bg-slate-900/60 rounded-lg p-4 backdrop-blur-sm border border-slate-800/60">
      <div className="flex justify-between items-start">{content}</div>
    </div>
  );
};

const SystemInfoModal = ({ isOpen, onClose, uptime }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [hasSelectedDate, setHasSelectedDate] = useState(false);
  const [view, setView] = useState('days'); // 'days', 'months', 'years'
  const [viewDate, setViewDate] = useState(new Date());

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setHasSelectedDate(true);
    setIsCalendarOpen(false);
  };

  const toggleCalendar = (e) => {
    e.stopPropagation();
    setIsCalendarOpen(prev => !prev);
  };


  const DatePicker = () => {
    const today = new Date();
    const startYear = Math.floor(viewDate.getFullYear() / 10) * 10;

    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const renderYears = () => (
      <div className="grid grid-cols-4 gap-1">
        {Array.from({ length: 12 }, (_, i) => {
          const year = startYear + i;
          const isCurrentYear = year === today.getFullYear();
          return (
            <button
              key={year}
              onClick={() => {
                setViewDate(new Date(year, viewDate.getMonth()));
                setView('months');
              }}
              className={`p-2 rounded text-sm ${
                isCurrentYear 
                  ? 'bg-emerald-500/20 text-emerald-400 font-bold' 
                  : 'text-slate-300 hover:bg-blue-500/20'
              }`}
            >
              {year}
            </button>
          );
        })}
      </div>
    );

    const renderMonths = () => (
      <div className="grid grid-cols-3 gap-1">
        {months.map((month, index) => {
          const isCurrentMonth = 
            index === today.getMonth() && 
            viewDate.getFullYear() === today.getFullYear();
          return (
            <button
              key={month}
              onClick={() => {
                setViewDate(new Date(viewDate.getFullYear(), index));
                setView('days');
              }}
              className={`p-2 rounded text-sm ${
                isCurrentMonth 
                  ? 'bg-emerald-500/20 text-emerald-400 font-bold' 
                  : 'text-slate-300 hover:bg-blue-500/20'
              }`}
            >
              {month.slice(0, 3)}
            </button>
          );
        })}
      </div>
    );

    const renderDays = () => {
      const daysInMonth = new Date(
        viewDate.getFullYear(),
        viewDate.getMonth() + 1,
        0
      ).getDate();
      const firstDay = new Date(
        viewDate.getFullYear(),
        viewDate.getMonth(),
        1
      ).getDay();

      return (
        <>
          <div className="grid grid-cols-7 gap-1">
            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
              <div key={day} className="text-center text-slate-400 text-sm py-2">
                {day}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-7 gap-1">
            {Array.from({ length: firstDay }).map((_, i) => (
              <div key={`empty-${i}`} className="p-2" />
            ))}
            {Array.from({ length: daysInMonth }, (_, i) => {
              const day = i + 1;
              const isToday = 
                day === today.getDate() &&
                viewDate.getMonth() === today.getMonth() &&
                viewDate.getFullYear() === today.getFullYear();
              
              return (
                <button
                  key={day}
                  onClick={() => handleDateSelect(new Date(viewDate.getFullYear(), viewDate.getMonth(), day))}
                  className={`p-2 rounded-full text-sm relative ${
                    isToday 
                      ? 'bg-emerald-500/20 text-emerald-400 font-bold' 
                      : 'text-slate-300 hover:bg-blue-500/20'
                  }`}
                >
                  {day}
                  {isToday && (
                    <span className="absolute bottom-1 left-1/2 -translate-x-1/2 h-1 w-1 bg-emerald-400 rounded-full" />
                  )}
                </button>
              );
            })}
          </div>
        </>
      );
    };

    return (
      <div className="absolute bottom-full right-0 mb-2 bg-slate-800 rounded-lg p-4 z-50 shadow-xl min-w-[320px]">
        <div className="flex items-center justify-between mb-4">
          <button
            onClick={() => {
              if (view === 'days') {
                setView('months');
              } else if (view === 'months') {
                setView('years');
              }
            }}
            className="text-slate-300 hover:text-white font-medium"
          >
            {view === 'days' 
              ? viewDate.toLocaleString('default', { month: 'long', year: 'numeric' })
              : view === 'months'
                ? viewDate.getFullYear()
                : `${startYear} - ${startYear + 11}`}
          </button>
          <div className="flex gap-1">
            <button
              onClick={() => {
                if (view === 'days') setViewDate(new Date(viewDate.getFullYear(), viewDate.getMonth() - 1));
                else if (view === 'months') setViewDate(new Date(viewDate.getFullYear() - 1, viewDate.getMonth()));
                else setViewDate(new Date(viewDate.getFullYear() - 10, viewDate.getMonth()));
              }}
              className="p-1 text-slate-400 hover:text-white rounded"
            >
              <ChevronLeft className="h-5 w-5" />
            </button>
            <button
              onClick={() => {
                if (view === 'days') setViewDate(new Date(viewDate.getFullYear(), viewDate.getMonth() + 1));
                else if (view === 'months') setViewDate(new Date(viewDate.getFullYear() + 1, viewDate.getMonth()));
                else setViewDate(new Date(viewDate.getFullYear() + 10, viewDate.getMonth()));
              }}
              className="p-1 text-slate-400 hover:text-white rounded"
            >
              <ChevronRight className="h-5 w-5" />
            </button>
          </div>
        </div>

        {view === 'days' && renderDays()}
        {view === 'months' && renderMonths()}
        {view === 'years' && renderYears()}
      </div>
    );
  };

  // Mock data for shutdown events
  // Mock data for shutdown events
  const getShutdownEvents = (date) => {
    if (date.toDateString() === new Date().toDateString()) {
      return [{
        date: date.toLocaleDateString(),
        from: '08:30',
        to: '09:45',
        duration: '1h 15m',
        reason: 'Scheduled Maintenance'
      }];
    }
    return [];
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl bg-[#0B1120] text-slate-100 border-slate-800">
        <DialogHeader>
          <DialogTitle className="text-lg sm:text-2xl font-bold text-slate-100 flex items-center gap-2">
            {/* <Activity className="h-5 sm:h-6 w-5 sm:w-6 text-blue-400" /> */}
            System Information
          </DialogTitle>
        </DialogHeader>

        <div className="mt-4 space-y-6">
          {/* Stats Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="bg-[#131B2F] rounded-lg p-4 flex flex-col">
              <span className="text-sm text-slate-400">System Uptime</span>
              <span className="text-2xl font-bold text-blue-400 mt-1">{uptime}</span>
              <Timer className="h-5 w-5 text-blue-400 mt-2 opacity-60" />
            </div>
            <div className="bg-[#131B2F] rounded-lg p-4 flex flex-col">
              <span className="text-sm text-slate-400">Last Update</span>
              <span className="text-2xl font-bold text-emerald-400 mt-1">27th Oct 2024</span>
              <Clock className="h-5 w-5 text-emerald-400 mt-2 opacity-60" />
            </div>
            <div className="bg-[#131B2F] rounded-lg p-4 flex flex-col">
              <span className="text-sm text-slate-400">AI Accuracy</span>
              <span className="text-2xl font-bold text-cyan-400 mt-1">98%</span>
              <Activity className="h-5 w-5 text-cyan-400 mt-2 opacity-60" />
            </div>
          </div>

          <div className="relative">
            {/* Centered header and calendar button */}
            <div className="flex flex-col items-center gap-4 mb-6">
              <h3 className="text-lg font-medium text-slate-200">System Shutdown History</h3>
              <div className="relative">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={toggleCalendar}
                  className="text-slate-300 min-w-[200px] justify-center"
                >
                  {hasSelectedDate 
                    ? selectedDate.toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                      })
                    : 'Select Date'}
                  <Calendar className="ml-2 h-4 w-4" />
                </Button>

                {isCalendarOpen && <DatePicker />}
              </div>
            </div>

            {/* Table only shown when date is selected */}
            {hasSelectedDate && (
  <div className="rounded-lg overflow-hidden border border-slate-800 mt-4 animate-in fade-in slide-in-from-top-4 duration-300">
    <table className="w-full">
      <thead>
        <tr className="bg-slate-800/50">
          <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">Date</th>
          <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">From</th>
          <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">To</th>
          <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">Duration</th>
          <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">Reason</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan="5" className="px-4 py-8 text-center text-slate-400">
            No shutdown events found for selected date
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};



const InspectionDashboard = ({ onDefectClick }) => {
  const [isSystemInfoModalOpen, setIsSystemInfoModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [frameCount, setFrameCount] = useState(0);
  const lastTimestamp = useRef(null);
  const imageRef = useRef(null);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [imageLoadError, setImageLoadError] = useState(false);
  const imageRetryCount = useRef(0);
  const lastImageETag = useRef(null);
  const MAX_RETRY_ATTEMPTS = 3;
  const [imageInfo, setImageInfo] = useState({ filename: '', timestamp: null });
  const [inspectionData, setInspectionData] = useState({
    currentShift: { totalInspected: 0, good: 0, defects: { total: 0 } },
    oee: { overall: 0, availability: 0, performance: 0, quality: 0 },
    realtimeMetrics: { shiftProgress: 0 }
  });
  // Add new state for uptime
  const [uptime, setUptime] = useState(null);
  const [isSystemOnline, setIsSystemOnline] = useState(true);
  const OFFLINE_THRESHOLD = 60000; // 1 minute in milliseconds
  const lastImageUpdateTime = useRef(new Date());
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);

  // Add new state for shutdown history
  const [shutdownHistory, setShutdownHistory] = useState([]);
  const lastImageCheck = useRef(new Date());
  const isShutdown = useRef(false);
  const SHUTDOWN_THRESHOLD = 120000; // 1 minute in milliseconds


  const [historicalData, setHistoricalData] = useState([]);
  const lastDataTimestamp = useRef(null);
  const [realtimeData, setRealtimeData] = useState([]);
  const dataQueue = useRef([]);
  const MAX_POINTS = 50;

  const [isProduction, setIsProduction] = useState(false);
  const lastInspectedCount = useRef(0);
  const productionCheckTimeout = useRef(null);

  // Function to check if production is active
  const checkProductionStatus = (currentCount) => {
    if (currentCount > lastInspectedCount.current) {
      setIsProduction(true);
      lastInspectedCount.current = currentCount;

      // Clear existing timeout
      if (productionCheckTimeout.current) {
        clearTimeout(productionCheckTimeout.current);
      }

      // Set new timeout - if no change in 5 seconds, mark as inactive
      productionCheckTimeout.current = setTimeout(() => {
        setIsProduction(false);
      }, 5000);
    }
  };
  const handleOpenSystemInfo = useCallback(() => {
    setIsSystemInfoModalOpen(true);
  }, []);
  
  const handleCloseSystemInfo = useCallback(() => {
    setIsSystemInfoModalOpen(false);
  }, []);
  
  
  // const SystemInfoModal = ({ isOpen, onClose }) => {
  //   const [state, setState] = useState({
  //     showCalendar: false,
  //     selectedDate: null,
  //     shutdownEvents: [
  //       {
  //         date: '2024-11-28',
  //         from: '08:30',
  //         to: '09:45',
  //         duration: '1h 15m',
  //         reason: 'Maintenance'
  //       }
  //     ]
  //   });
  
  //   // Use memoized handlers to prevent unnecessary re-renders
  //   const handleDateSelect = useCallback((date) => {
  //     setState(prev => ({
  //       ...prev,
  //       selectedDate: date,
  //       showCalendar: false
  //     }));
  //   }, []);
  
  //   const toggleCalendar = useCallback((e) => {
  //     e.stopPropagation(); // Prevent event bubbling
  //     setState(prev => ({
  //       ...prev,
  //       showCalendar: !prev.showCalendar
  //     }));
  //   }, []);
  
  //   const handleClose = useCallback(() => {
  //     setState(prev => ({
  //       ...prev,
  //       showCalendar: false
  //     }));
  //     onClose();
  //   }, [onClose]);
  
  //   // Close calendar when clicking outside
  //   useEffect(() => {
  //     const handleClickOutside = (e) => {
  //       if (state.showCalendar && !e.target.closest('.calendar-container')) {
  //         setState(prev => ({
  //           ...prev,
  //           showCalendar: false
  //         }));
  //       }
  //     };
  
  //     if (state.showCalendar) {
  //       document.addEventListener('mousedown', handleClickOutside);
  //       return () => document.removeEventListener('mousedown', handleClickOutside);
  //     }
  //   }, [state.showCalendar]);
  
  //   // Reset state when modal closes
  //   useEffect(() => {
  //     if (!isOpen) {
  //       setState(prev => ({
  //         ...prev,
  //         showCalendar: false
  //       }));
  //     }
  //   }, [isOpen]);
  
  //   const timeRef = useRef({
  //     uptime: { days: 15, hours: 8, minutes: 45 },
  //     lastUpdate: new Date()
  //   });
  
  //   return (
  //     <Dialog open={isOpen} onOpenChange={handleClose}>
  //       <DialogContent className="max-w-4xl bg-[#0B1120] text-slate-100 border-slate-800">
  //         <DialogHeader>
  //           <DialogTitle className="text-lg sm:text-2xl font-bold text-slate-100 flex items-center gap-2">
  //             <Activity className="h-5 sm:h-6 w-5 sm:w-6 text-blue-400" />
  //             System Information
  //           </DialogTitle>
  //         </DialogHeader>
  
  //         <div className="mt-4 space-y-6">
  //           <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
  //             <div className="bg-[#131B2F] rounded-lg p-4 flex flex-col">
  //               <span className="text-sm text-slate-400">System Uptime</span>
  //               <span className="text-2xl font-bold text-blue-400 mt-1">
  //                 {`${timeRef.current.uptime.days}d ${timeRef.current.uptime.hours}h ${timeRef.current.uptime.minutes}m`}
  //               </span>
  //               <Timer className="h-5 w-5 text-blue-400 mt-2 opacity-60" />
  //             </div>
  //             <div className="bg-[#131B2F] rounded-lg p-4 flex flex-col">
  //               <span className="text-sm text-slate-400">Last Update</span>
  //               <span className="text-2xl font-bold text-emerald-400 mt-1">
  //                 27th Oct 2024
  //               </span>
  //               <Clock className="h-5 w-5 text-emerald-400 mt-2 opacity-60" />
  //             </div>
  //             <div className="bg-[#131B2F] rounded-lg p-4 flex flex-col">
  //               <span className="text-sm text-slate-400">AI Accuracy</span>
  //               <span className="text-2xl font-bold text-cyan-400 mt-1">98%</span>
  //               <Activity className="h-5 w-5 text-cyan-400 mt-2 opacity-60" />
  //             </div>
  //           </div>
  
  //           <div className="relative">
  //             <div className="flex items-center justify-between mb-4">
  //               <h3 className="text-lg font-medium text-slate-200">System Shutdown History</h3>
  //               <div className="relative calendar-container">
  //                 <Button
  //                   variant="outline"
  //                   size="sm"
  //                   onClick={toggleCalendar}
  //                   className="text-slate-300"
  //                 >
  //                   {state.selectedDate 
  //                     ? state.selectedDate.toLocaleDateString('en-US', { 
  //                         month: 'long', 
  //                         day: 'numeric', 
  //                         year: 'numeric' 
  //                       })
  //                     : 'Select Date'}
  //                   <Calendar className="ml-2 h-4 w-4" />
  //                 </Button>
  
  //                 {state.showCalendar && (
  //                   <div className="absolute z-50 top-full mt-2 right-0">
  //                     <DatePicker
  //                       onSelect={handleDateSelect}
  //                       onClose={() => setState(prev => ({ ...prev, showCalendar: false }))}
  //                     />
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  
  //             <div className="rounded-lg overflow-hidden border border-slate-800 mt-4">
  //               <table className="w-full">
  //                 <thead>
  //                   <tr className="bg-slate-800/50">
  //                     <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">Date</th>
  //                     <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">From</th>
  //                     <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">To</th>
  //                     <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">Duration</th>
  //                     <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">Reason</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   {state.shutdownEvents.length > 0 ? (
  //                     state.shutdownEvents.map((event, index) => (
  //                       <tr key={index} className="hover:bg-slate-800/30">
  //                         <td className="px-4 py-3 text-sm text-slate-300">{event.date}</td>
  //                         <td className="px-4 py-3 text-sm text-slate-300">{event.from}</td>
  //                         <td className="px-4 py-3 text-sm text-slate-300">{event.to}</td>
  //                         <td className="px-4 py-3 text-sm text-slate-300">{event.duration}</td>
  //                         <td className="px-4 py-3 text-sm text-slate-300">{event.reason}</td>
  //                       </tr>
  //                     ))
  //                   ) : (
  //                     <tr>
  //                       <td colSpan="5" className="px-4 py-8 text-center text-slate-400">
  //                         No shutdown events found for the selected date
  //                       </td>
  //                     </tr>
  //                   )}
  //                 </tbody>
  //               </table>
  //             </div>
  //           </div>
  //         </div>
  //       </DialogContent>
  //     </Dialog>
  //   );
  // };
  

  const handleDefectClick = () => {
    const today = new Date();
    const formattedDate = `${today.getFullYear().toString().slice(-2)}_${(today.getMonth() + 1).toString().padStart(2, '0')}_${today.getDate().toString().padStart(2, '0')}`;
    onDefectClick('historie', formattedDate);
  };

  const updateRealtimeData = (newData) => {
    setRealtimeData(prevData => {
      let updatedData = [...prevData, newData];
      if (updatedData.length > MAX_POINTS) {
        updatedData = updatedData.slice(-MAX_POINTS);
      }
      return updatedData;
    });
  };

  const formatChartTime = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
  };

  const getTodayId = () => {
    const today = new Date();
    return today.getFullYear().toString() +
      (today.getMonth() + 1).toString().padStart(2, '0') +
      today.getDate().toString().padStart(2, '0');
  };

  // Modify fetchDynamoData to include production check
  const fetchDynamoData = async () => {
    try {
      const params = {
        TableName: 'saudican',
        Key: { id: getTodayId() }
      };

      const result = await dynamoDB.get(params).promise();

      if (result.Item) {
        const data = result.Item;
        const quality = (data.good_count / data.total_count * 100) || 0;
        const performance = 94.2;
        const availability = 96.8;
        const overall = ((quality * performance * availability) / 10000).toFixed(1);

        // Check production status based on total_count changes
        checkProductionStatus(data.total_count);

        setInspectionData({
          currentShift: {
            totalInspected: data.total_count,
            good: data.good_count,
            defects: { total: data.defect_count }
          },
          oee: {
            overall: parseFloat(overall),
            availability: availability,
            performance: performance,
            quality: parseFloat(quality.toFixed(1))
          },
          realtimeMetrics: {
            shiftProgress: calculateShiftProgress()
          }
        });

        updateRealtimeData({
          time: new Date().toLocaleTimeString(),
          good: data.good_count || 0,
          defects: data.defect_count || 0
        });
      }
    } catch (error) {
      console.error('Error fetching DynamoDB data:', error);
      setIsProduction(false);
    }
  };

  // Clean up the production check timeout
  useEffect(() => {
    return () => {
      if (productionCheckTimeout.current) {
        clearTimeout(productionCheckTimeout.current);
      }
    };
  }, []);



  // Modify checkForNewImage to include shutdown detection
  // Modify checkForNewImage to handle system online/offline status
  const checkForNewImage = async () => {
    try {
      const headParams = {
        Bucket: 'saudican',
        Key: 'realtime_image/image.png'
      };

      const headData = await s3.headObject(headParams).promise();
      const currentETag = headData.ETag;
      const now = new Date();
      const imageLastModified = new Date(headData.LastModified);
      const timeDifference = now - imageLastModified;

      // Check if image is more than 30 seconds old
      if (timeDifference > 30000) {
        setIsSystemOnline(false);

        // if (!isShutdown.current) {
        //   isShutdown.current = true;
        //   // Record shutdown event
        //   const shutdownEvent = {
        //     startTime: imageLastModified,
        //     endTime: now,
        //     duration: Math.floor(timeDifference / 1000)
        //   };
        //   await saveShutdownEvent(shutdownEvent);
        //   setShutdownHistory(prev => [shutdownEvent, ...prev].slice(0, 10));
        // }
        return;
      }

      // If image is recent, update system status and fetch image
      if (currentETag !== lastImageETag.current) {
        lastImageETag.current = currentETag;
        lastImageUpdateTime.current = imageLastModified;
        setIsSystemOnline(true);
        isShutdown.current = false;
        await fetchLatestImage();
      }

    } catch (error) {
      console.error('Error checking for new image:', error);
      setIsSystemOnline(false);
      handleImageError();
    }
  };

  // Add effect to load shutdown history on component mount


  // Function to format duration
  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let formatted = '';
    if (hours > 0) formatted += `${hours}h `;
    if (minutes > 0) formatted += `${minutes}m `;
    formatted += `${remainingSeconds}s`;

    return formatted.trim();
  };

  const fetchLatestImage = async () => {
    try {
      const params = {
        Bucket: 'saudican',
        Key: 'realtime_image/image.png',
        Expires: 60
      };

      const url = await s3.getSignedUrlPromise('getObject', params);
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000);

      try {
        const response = await fetch(url, {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          },
          signal: controller.signal
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);

        setCurrentImage(imageUrl);
        setFrameCount(prev => prev + 1);
        setImageInfo({
          filename: 'image.png',
          timestamp: new Date()
        });

        imageRetryCount.current = 0;
        setImageLoadError(false);

        if (imageRef.current) {
          URL.revokeObjectURL(imageRef.current.src);
        }
      } finally {
        clearTimeout(timeoutId);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted - timeout');
      } else {
        console.error('Error fetching image:', error);
      }
      handleImageError();
    }
  };

  const handleImageError = () => {
    setIsImageLoading(false);
    setImageLoadError(true);

    if (imageRetryCount.current < MAX_RETRY_ATTEMPTS) {
      const retryDelay = Math.pow(2, imageRetryCount.current) * 1000;
      imageRetryCount.current += 1;
      setTimeout(checkForNewImage, retryDelay);
    }
  };

  const handleImageLoad = () => {
    setIsImageLoading(false);
    setImageLoadError(false);
    imageRetryCount.current = 0;
  };

  const getCurrentShift = () => {
    const now = new Date();
    const hour = now.getHours();
    return hour >= 6 && hour < 18 ? 1 : 2;
  };


  const calculateShiftProgress = () => {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const currentSecond = now.getSeconds();

    // Define shift boundaries
    const shift1Start = 6; // 6 AM
    const shift2Start = 18; // 6 PM

    // Calculate total minutes elapsed in current shift
    let minutesElapsed;
    let currentShift;

    if (currentHour >= shift1Start && currentHour < shift2Start) {
      // Shift 1
      currentShift = 1;
      minutesElapsed = ((currentHour - shift1Start) * 60) + currentMinute;
    } else {
      // Shift 2
      currentShift = 2;
      if (currentHour >= shift2Start) {
        minutesElapsed = ((currentHour - shift2Start) * 60) + currentMinute;
      } else {
        // After midnight
        minutesElapsed = ((currentHour + 24 - shift2Start) * 60) + currentMinute;
      }
    }

    // Calculate progress percentage (12 hours = 720 minutes)
    const totalShiftMinutes = 12 * 60;
    const progress = (minutesElapsed / totalShiftMinutes) * 100;

    // Ensure progress stays between 0 and 100
    return Math.min(Math.max(progress, 0), 100).toFixed(1);
  };

  const getShiftTimeRemaining = (currentShift) => {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();

    let endHour = currentShift === 1 ? 18 : 6; // 6 PM for Shift 1, 6 AM for Shift 2
    let remainingHours;
    let remainingMinutes;

    if (currentShift === 2 && currentHour >= 18) {
      // After 6 PM, calculate remaining time until 6 AM next day
      remainingHours = (12 - currentHour) + endHour;
    } else if (currentShift === 2 && currentHour < 6) {
      // After midnight, calculate remaining time until 6 AM
      remainingHours = endHour - currentHour;
    } else {
      // Shift 1 or normal calculation
      if (currentHour >= endHour) {
        remainingHours = (12 - currentHour) + endHour;
      } else {
        remainingHours = endHour - currentHour;
      }
    }

    remainingMinutes = 60 - currentMinute;
    if (remainingMinutes === 60) {
      remainingMinutes = 0;
    } else {
      remainingHours--;
    }

    return `Shift ${getCurrentShift()}: ${remainingHours}h ${remainingMinutes}m remaining`;
  };

  useEffect(() => {
    let isSubscribed = true;

    const updateImage = async () => {
      if (!isSubscribed) return;
      await checkForNewImage();
    };

    updateImage();
    const intervalId = setInterval(updateImage, 100);

    return () => {
      isSubscribed = false;
      clearInterval(intervalId);
      if (currentImage) {
        URL.revokeObjectURL(currentImage);
      }
    };
  }, []);

  useEffect(() => {
    const dynamoInterval = setInterval(fetchDynamoData, 1000);
    return () => clearInterval(dynamoInterval);
  }, []);

  // Function to calculate and format uptime
  const calculateUptime = () => {
    const now = new Date();
    const timeDiff = now - INITIAL_START_DATE;

    // Convert milliseconds to days, hours, minutes
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return {
      days,
      hours,
      minutes
    };
  };


  // Add uptime counter effect
  // Update uptime every minute
  useEffect(() => {
    // Initial calculation
    setUptime(calculateUptime());

    // Update every minute
    const interval = setInterval(() => {
      setUptime(calculateUptime());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const formatUptime = () => {
    if (!uptime) return 'Calculating...';
    return `${uptime.days}d ${uptime.hours}h ${uptime.minutes}m`;
  };
  return (
    <PageContainer>
      <div className="w-full h-full overflow-y-auto">
        {/* Status Bar */}
        <div className="w-full bg-slate-900/60 backdrop-blur-sm border border-slate-800/60 rounded-lg p-2 1xl:p-4 mb-2 1xl:mb-4">
          <div className="flex items-center justify-center gap-4 2xl:gap-8">
            <div className="flex items-center gap-2">
              <span className="text-xs 2xl:text-sm text-slate-400">System Status:</span>
              <span className={`px-2 2xl:px-3 py-0.5 2xl:py-1 rounded-full flex items-center gap-1.5 ${
                isSystemOnline ? 'bg-emerald-500/10 text-emerald-400' : 'bg-red-500/10 text-red-400'
              } text-xs 2xl:text-sm font-medium`}>
                <span className={`h-1.5 w-1.5 2xl:h-2 2xl:w-2 rounded-full ${
                  isSystemOnline ? 'bg-emerald-400 animate-pulse' : 'bg-red-400'
                }`} />
                {isSystemOnline ? 'ON' : 'OFF'}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-xs 2xl:text-sm text-slate-400">Production:</span>
              <span className={`px-2 2xl:px-3 py-0.5 2xl:py-1 rounded-full flex items-center gap-1.5 ${
                isProduction ? 'bg-green-500/10 text-green-400' : 'bg-red-500/10 text-red-400'
              } text-xs 2xl:text-sm font-medium`}>
                <span className={`h-1.5 w-1.5 2xl:h-2 2xl:w-2 rounded-full ${
                  isProduction ? 'bg-green-400 animate-pulse' : 'bg-red-400'
                }`} />
                {isProduction ? 'ON' : 'OFF'}
              </span>
            </div>
          </div>
        </div>
        
        <div className="w-full p-2 sm:p-3 md:p-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 sm:gap-3 md:gap-4">
      {/* Total Inspected */}
      <Button
        variant="ghost"
        className="bg-slate-900/60 backdrop-blur-sm border border-slate-800/60 rounded-lg p-3 sm:p-4 h-full w-full hover:bg-slate-900/80"
        onClick={() => setIsStatsModalOpen(true)}
      >
        <div className="w-full flex flex-col h-full">
          <div className="flex items-center justify-between">
            <span className="text-slate-400 text-xs sm:text-sm">Total Inspected</span>
            <span className="text-blue-400">
              <Scale className="h-4 w-4 sm:h-5 sm:w-5" />
            </span>
          </div>
          <div className="flex flex-col flex-1 justify-center items-center">
            <span className="text-xl sm:text-2xl font-bold text-blue-400">
              {inspectionData.currentShift.totalInspected}
            </span>
          </div>
          <span className="text-[10px] sm:text-xs text-slate-400">
            Click to view production statistics
          </span>
        </div>
      </Button>

      {/* Good */}
      <div className="bg-slate-900/60 backdrop-blur-sm border border-slate-800/60 rounded-lg p-3 sm:p-4 h-full">
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between">
            <span className="text-slate-400 text-xs sm:text-sm">Good</span>
            <span className="text-emerald-400">
              <Check className="h-4 w-4 sm:h-5 sm:w-5" />
            </span>
          </div>
          <div className="flex flex-col flex-1 justify-center items-center">
            <span className="text-xl sm:text-2xl font-bold text-emerald-400">
              {inspectionData.currentShift.good}
            </span>
          </div>
          <div className="invisible text-[10px] sm:text-xs">
            {/* Spacer for alignment */}
            Spacer text
          </div>
        </div>
      </div>

      {/* Defects */}
      <Button
        variant="ghost"
        className="bg-slate-900/60 backdrop-blur-sm border border-slate-800/60 rounded-lg p-3 sm:p-4 h-full w-full hover:bg-slate-900/80"
        onClick={handleDefectClick}
      >
        <div className="w-full flex flex-col h-full">
          <div className="flex items-center justify-between">
            <span className="text-slate-400 text-xs sm:text-sm">Defects</span>
            <span className="text-rose-400">
              <XCircle className="h-4 w-4 sm:h-5 sm:w-5" />
            </span>
          </div>
          <div className="flex flex-col flex-1 justify-center items-center">
            <span className="text-xl sm:text-2xl font-bold text-rose-400">
              {inspectionData.currentShift.defects.total}
            </span>
          </div>
          <span className="text-[10px] sm:text-xs text-slate-400">
            Click to view the Defects
          </span>
        </div>
      </Button>

      {/* Real-Time OEE */}
      <div className="bg-slate-900/60 backdrop-blur-sm border border-slate-800/60 rounded-lg p-3 sm:p-4 h-full">
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between">
            <span className="text-slate-400 text-xs sm:text-sm">Real-Time OEE</span>
            <span className="text-cyan-400">
              <Percent className="h-4 w-4 sm:h-5 sm:w-5" />
            </span>
          </div>
          <div className="flex flex-col flex-1 justify-center items-center">
            <span className="text-xl sm:text-2xl font-bold text-cyan-400">
              {inspectionData.oee.overall}%
            </span>
          </div>
          <div className="invisible text-[10px] sm:text-xs">
            {/* Spacer for alignment */}
            Spacer text
          </div>
        </div>
      </div>

      {/* Shift Progress */}
      <div className="bg-slate-900/60 backdrop-blur-sm border border-slate-800/60 rounded-lg p-3 sm:p-4 h-full">
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between">
            <span className="text-slate-400 text-xs sm:text-sm">{`Shift ${getCurrentShift()}`}</span>
            <span className="text-cyan-400 opacity-0">
              <Percent className="h-4 w-4 sm:h-5 sm:w-5" />
            </span>
          </div>
          <div className="flex flex-col flex-1 justify-center items-center">
            <div className="relative w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16">
              <svg className="w-full h-full -rotate-90 transform" viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  className="stroke-slate-700/30"
                  strokeWidth="8"
                  fill="none"
                />
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  className="stroke-cyan-400/60"
                  strokeWidth="8"
                  fill="none"
                  strokeLinecap="round"
                  style={{
                    filter: 'drop-shadow(0 0 6px rgba(34, 211, 238, 0.3))',
                    transition: 'stroke-dashoffset 0.5s ease',
                    strokeDasharray: '100 100',
                    strokeDashoffset: `${100 - parseFloat(inspectionData.realtimeMetrics.shiftProgress)}`
                  }}
                  pathLength="100"
                />
              </svg>
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <span className="text-xs sm:text-sm font-bold text-cyan-400">
                  {inspectionData.realtimeMetrics.shiftProgress}%
                </span>
              </div>
            </div>
          </div>
          <span className="text-[10px] sm:text-xs text-slate-400">
            {getShiftTimeRemaining()}
          </span>
        </div>
      </div>
    </div>
      
      </div>

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-2 2xl:gap-4">
          {/* Left Column */}
          <div className="lg:col-span-8">
            {/* Chart */}
            <div className="bg-slate-900/60 backdrop-blur-sm border border-slate-800/60 rounded-lg p-2 2xl:p-4">
              <div className="flex items-center gap-2 mb-2 2xl:mb-4">
                <BarChart3 className="h-4 2xl:h-5 w-4 2xl:w-5 text-blue-400" />
                <h2 className="text-sm 2xl:text-base font-semibold text-slate-200">Real-Time Inspection Analysis</h2>
              </div>
              <div className="h-[250px] sm:h-[300px] 2xl:h-[400px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={realtimeData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#1e293b" />
                    <XAxis dataKey="time" stroke="#64748b" tick={{ fill: '#64748b', fontSize: '10px' }} interval="preserveStartEnd" />
                    <YAxis stroke="#64748b" tick={{ fill: '#64748b', fontSize: '10px' }} domain={[0, 'auto']} />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: '#0f172a',
                        border: '1px solid #1e293b',
                        borderRadius: '6px',
                        fontSize: '12px'
                      }}
                    />
                    <Line type="monotone" dataKey="good" stroke="#10b981" strokeWidth={2} dot={false} isAnimationActive={false} name="Good Parts" />
                    <Line type="monotone" dataKey="defects" stroke="#f43f5e" strokeWidth={2} dot={false} isAnimationActive={false} name="Defects" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
  
            {/* OEE and System Info in one line */}
            <div className="grid grid-cols-2 gap-2 2xl:gap-4 mt-2 2xl:mt-4">
              <OEEMetrics
                quality={inspectionData.oee.quality}
                performance={inspectionData.oee.performance}
                availability={inspectionData.oee.availability}
              />
  
  <div className="bg-slate-900/60 backdrop-blur-sm border border-slate-800/60 rounded-lg p-2 2xl:p-4">
  <button
  onClick={handleOpenSystemInfo}  // Changed this line
  className="w-full h-full flex flex-col justify-between"
>
    <div className="flex flex-col items-start gap-1">
      <div className="flex items-center gap-2">
        <Activity className="h-4 2xl:h-5 w-4 2xl:w-5 text-blue-400" />
        <h2 className="text-sm 2xl:text-base font-semibold text-slate-200">System Information</h2>
      </div>
      <p className="text-xs text-slate-400">Click to see more information about the system</p>
    </div>

    <div className="flex flex-wrap items-center justify-between gap-4 mt-2">
      <div className="flex items-center gap-2">
        <Timer className="h-3 w-3 text-blue-400" />
        <span className="text-xs text-slate-400">Uptime:</span>
        <span className="text-sm font-medium text-blue-400">{formatUptime()}</span>
      </div>

      <div className="flex items-center gap-2">
        <Clock className="h-3 w-3 text-emerald-400" />
        <span className="text-xs text-slate-400">Last Update:</span>
        <span className="text-sm font-medium text-emerald-400">27th Oct 2024</span>
      </div>

      <div className="flex items-center gap-2">
        <CheckCircle className="h-3 w-3 text-green-400" />
        <span className="text-xs text-slate-400">Ai Accuracy:</span>
        <span className="text-sm font-medium text-green-400">98%</span>
      </div>

      <div className="flex items-center gap-2">
        <AlertCircle className="h-3 w-3 text-red-400" />
        <span className="text-xs text-slate-400">System Off Times:</span>
        <span className="text-sm font-medium text-red-400">0</span>
      </div>
    </div>
  </button>
</div>


            </div>
          </div>
  
          {/* Right Column - Camera Feed */}
          <div className="lg:col-span-4">
            <div className="bg-slate-900/60 backdrop-blur-sm border border-slate-800/60 rounded-lg p-2 2xl:p-4">
              <div className="flex items-center justify-between mb-2 2xl:mb-4">
                <div className="flex items-center gap-2">
                  <Camera className="h-4 2xl:h-5 w-4 2xl:w-5 text-blue-400" />
                  <h2 className="text-sm 2xl:text-base font-semibold text-slate-200">Real-Time Feed</h2>
                </div>
                <div className="flex items-center gap-2">
                  <span className={`px-2 2xl:px-3 py-0.5 2xl:py-1 rounded-full ${
                    isSystemOnline ? 'bg-emerald-500/10 text-emerald-400' : 'bg-red-500/10 text-red-400'
                  } text-xs 2xl:text-sm font-medium flex items-center gap-1.5`}>
                    <span className={`h-1.5 w-1.5 2xl:h-2 2xl:w-2 rounded-full ${
                      isSystemOnline ? 'bg-emerald-400 animate-pulse' : 'bg-red-400'
                    }`} />
                    {isSystemOnline ? 'ON' : 'OFF'}
                  </span>
                </div>
              </div>
  
              <div className="relative aspect-square bg-black rounded-lg overflow-hidden">
                {isImageLoading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black/50 z-10">
                    <div className="animate-spin rounded-full h-6 2xl:h-8 w-6 2xl:w-8 border-b-2 border-blue-400" />
                  </div>
                )}
  
                {!isSystemOnline ? (
                  <div className="absolute inset-0 flex flex-col items-center justify-center bg-slate-900/90 z-20">
                    <div className="bg-red-500/10 p-3 2xl:p-4 rounded-lg border border-red-500/20 text-center">
                      <Camera className="h-8 2xl:h-12 w-8 2xl:w-12 text-red-400 mx-auto mb-2 2xl:mb-3 opacity-50" />
                      <h3 className="text-sm 2xl:text-base text-red-400 font-medium mb-1">System Offline</h3>
                      <p className="text-xs 2xl:text-sm text-slate-400">
                        Unable to connect to the camera feed.<br />
                        Please check your connection.
                      </p>
                    </div>
                  </div>
                ) : (
                  currentImage && (
                    <img
                      ref={imageRef}
                      src={currentImage}
                      alt="Real-Time Analysis"
                      className="w-full h-full object-contain"
                      onError={handleImageError}
                      onLoad={handleImageLoad}
                      crossOrigin="anonymous"
                      style={{
                        display: isImageLoading ? 'none' : 'block',
                        imageRendering: 'pixelated'}}
                        />
                      )
                    )}
      
                    {imageLoadError && !isImageLoading && isSystemOnline && (
                      <div className="absolute inset-0 flex items-center justify-center bg-red-900/20">
                        <div className="text-xs 2xl:text-sm text-red-400">
                          Reconnecting... ({imageRetryCount.current}/{MAX_RETRY_ATTEMPTS})
                        </div>
                      </div>
                    )}
      
                    <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/90 to-transparent p-2">
                      <div className="flex justify-between items-center">
                        <div>
                          <span className="text-[10px] 2xl:text-xs text-slate-400">
                            Frame #{isSystemOnline ? frameCount : '-'}
                          </span>
                          <div className="text-[8px] 2xl:text-[10px] text-slate-500 mt-0.5">
                            Last Update: {isSystemOnline ? (
                              imageInfo.timestamp ?
                                imageInfo.timestamp.toLocaleTimeString('en-US', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  second: '2-digit',
                                  fractionalSecondDigits: 3
                                })
                                : 'Loading...'
                            ) : 'System Offline'}
                          </div>
                        </div>
                        <span className={`text-[10px] 2xl:text-xs ${isSystemOnline ? 'text-cyan-400' : 'text-red-400'}`}>
                          {isSystemOnline ? imageInfo.filename || 'Waiting...' : 'Disconnected'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
            <ProductionStatisticsModal 
        isOpen={isStatsModalOpen}
        onClose={() => setIsStatsModalOpen(false)}
        inspectionData={inspectionData}
      />
<SystemInfoModal 
  isOpen={isSystemInfoModalOpen}
  onClose={handleCloseSystemInfo}  
  uptime={formatUptime()}  // Pass the formatted uptime
/>
          </div>
        </PageContainer>
      );}

      export default InspectionDashboard;  