// src/utils/analytics.js
import mixpanel from 'mixpanel-browser';

export const GA4_MEASUREMENT_ID = 'G-B5NZJGWFEC';
const MIXPANEL_TOKEN = 'aac3d7e66a6192aea27649902d7372ca'; // Replace with your Mixpanel token

// Initialize Mixpanel
mixpanel.init(MIXPANEL_TOKEN, {
  debug: true, // Set to false in production
  track_pageview: true,
  persistence: 'localStorage'
});

// Get user's location
const getUserLocation = async () => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    return {
      city: data.city || 'Unknown',
      region: data.region || 'Unknown',
      country: data.country_name || 'Unknown'
    };
  } catch (error) {
    console.error('Error getting location:', error);
    return { city: 'Unknown', region: 'Unknown', country: 'Unknown' };
  }
};

// Initialize both analytics services
export const initializeAnalytics = async () => {
  try {
    const location = await getUserLocation();

    // Initialize GA4
    if (window.gtag) {
      window.gtag('config', GA4_MEASUREMENT_ID, {
        custom_map: {
          'dimension1': 'user_city',
          'dimension2': 'user_region',
          'dimension3': 'user_country'
        },
        user_city: location.city,
        user_region: location.region,
        user_country: location.country
      });
    }

    // Set Mixpanel user properties
    mixpanel.register({
      city: location.city,
      region: location.region,
      country: location.country
    });

  } catch (error) {
    console.error('Error initializing analytics:', error);
  }
};

// Track page views
export const trackPageView = async (pageName, pageTitle) => {
  const location = await getUserLocation();

  // GA4 tracking
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      page_title: pageTitle || pageName,
      page_path: `/${pageName}`,
      user_city: location.city,
      user_region: location.region,
      user_country: location.country,
      send_to: GA4_MEASUREMENT_ID
    });
  }

  // Mixpanel tracking
  mixpanel.track('Page View', {
    page: pageName,
    title: pageTitle,
    url: window.location.href,
    city: location.city,
    region: location.region,
    country: location.country
  });
};

// Track events
export const trackEvent = async (eventName, parameters = {}) => {
  const location = await getUserLocation();

  // GA4 tracking
  if (window.gtag) {
    window.gtag('event', eventName, {
      ...parameters,
      user_city: location.city,
      user_region: location.region,
      user_country: location.country,
      send_to: GA4_MEASUREMENT_ID
    });
  }

  // Mixpanel tracking
  mixpanel.track(eventName, {
    ...parameters,
    city: location.city,
    region: location.region,
    country: location.country
  });
};

// Track user interactions
export const trackUserInteraction = async (action, label = null, value = null) => {
  const location = await getUserLocation();

  // GA4 tracking
  if (window.gtag) {
    window.gtag('event', 'user_interaction', {
      action_type: action,
      action_label: label,
      action_value: value,
      user_city: location.city,
      user_region: location.region,
      user_country: location.country,
      send_to: GA4_MEASUREMENT_ID
    });
  }

  // Mixpanel tracking
  mixpanel.track('User Interaction', {
    action: action,
    label: label,
    value: value,
    city: location.city,
    region: location.region,
    country: location.country
  });
};

// Track errors
export const trackError = async (error, context = '') => {
  const location = await getUserLocation();

  // GA4 tracking
  if (window.gtag) {
    window.gtag('event', 'error', {
      error_message: error.message,
      error_type: error.name,
      error_context: context,
      user_city: location.city,
      user_region: location.region,
      user_country: location.country,
      send_to: GA4_MEASUREMENT_ID
    });
  }

  // Mixpanel tracking
  mixpanel.track('Error', {
    error_message: error.message,
    error_type: error.name,
    error_context: context,
    city: location.city,
    region: location.region,
    country: location.country
  });
};

// Identify user (if needed)
export const identifyUser = (userId, userProperties = {}) => {
  // Set user ID in GA4
  if (window.gtag) {
    window.gtag('set', 'user_properties', {
      user_id: userId,
      ...userProperties
    });
  }

  // Set user ID in Mixpanel
  mixpanel.identify(userId);
  mixpanel.people.set({
    ...userProperties,
    $last_seen: new Date().toISOString()
  });
};