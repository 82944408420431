// src/components/ui/button.js
import * as React from "react";
import { cn } from "../../lib/utils";

const buttonVariants = {
  default: "bg-blue-600 hover:bg-blue-700 text-white",
  outline: "border border-gray-600 hover:bg-gray-700 text-gray-100",
  destructive: "bg-red-600 hover:bg-red-700 text-white",
};

const buttonSizes = {
  default: "h-10 px-4 py-2",
  sm: "h-8 px-3 py-1",
  lg: "h-12 px-6 py-3",
  icon: "h-10 w-10",
};

const Button = React.forwardRef(({ 
  className, 
  variant = "default", 
  size = "default", 
  children, 
  ...props 
}, ref) => {
  return (
    <button
      className={cn(
        "inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 disabled:pointer-events-none disabled:opacity-50",
        buttonVariants[variant],
        buttonSizes[size],
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  );
});
Button.displayName = "Button";

export { Button };