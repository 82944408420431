import React, { useRef, useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { 
  Grid, List, ZoomIn, ZoomOut, ArrowLeft, ArrowRight, 
  X, Search, Loader2, ChevronLeft, ChevronRight 
} from 'lucide-react';

import AWS from 'aws-sdk';

// Initialize AWS
AWS.config.update({
    region: 'us-east-1',
    accessKeyId: 'AKIA3LET6GOC2WEU2DWR',
    secretAccessKey: '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'
});
const HIDDEN_BOXES_STORAGE_KEY = 'viewedImages';

const S3_BUCKET = 'saudican';
const FOLDER_PREFIX = 'dataImages/';

const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const HistoryView = ({ initialDate = null }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState('grid');
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const [filteredImages, setFilteredImages] = useState([]);
  const [availableDates, setAvailableDates] = useState(new Set());

  const [currentMonth, setCurrentMonth] = useState(new Date());
  // const [hiddenBoxes, setHiddenBoxes] = useState(new Set());

const loadFromLocalStorage = () => {
  try {
    const stored = localStorage.getItem(HIDDEN_BOXES_STORAGE_KEY);
    return stored ? new Set(JSON.parse(stored)) : new Set();
  } catch (error) {
    console.error('Error loading from localStorage:', error);
    return new Set();
  }
};
  const [hiddenBoxes, setHiddenBoxes] = useState(() => loadFromLocalStorage());


  const calendarRef = useRef(null);
  const today = new Date();
  const s3 = new AWS.S3();

  // Initialize with today's date
  const formatToFolderDate = (date) => {
    const yy = date.getFullYear().toString().slice(-2);
    const mm = (date.getMonth() + 1).toString().padStart(2, '0');
    const dd = date.getDate().toString().padStart(2, '0');
    return `${yy}_${mm}_${dd}`;
  };

  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    return formatToFolderDate(today);
  });

  // Utility functions for localStorage
const saveToLocalStorage = (imageKeys) => {
  try {
    const keysArray = Array.from(imageKeys);
    localStorage.setItem(HIDDEN_BOXES_STORAGE_KEY, JSON.stringify(keysArray));
  } catch (error) {
    console.error('Error saving to localStorage:', error);
  }
};

useEffect(() => {
  saveToLocalStorage(hiddenBoxes);
}, [hiddenBoxes]);

  // Keep all existing logic
  const toggleBoundingBox = (imageKey, e) => {
    e.stopPropagation();
    setHiddenBoxes(prev => {
      const newBoxes = new Set(prev);
      if (newBoxes.has(imageKey)) {
        newBoxes.delete(imageKey);
      } else {
        newBoxes.add(imageKey);
      }
      saveToLocalStorage(newBoxes); // Save immediately
      return newBoxes;
    });
  };
  
  // Add a function to clear viewed state if needed
  const clearViewedState = () => {
    setHiddenBoxes(new Set());
    localStorage.removeItem(HIDDEN_BOXES_STORAGE_KEY);
  };
  const handleOutsideClick = (e) => {
    if (e.target.closest('.max-w-5xl') || e.target.closest('.calendar-sidebar')) {
      return;
    }
    if (selectedImage) {
      setSelectedImage(null);
    }
  };

  // Calendar helpers
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getCalendarDays = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = getDaysInMonth(year, month);
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(null);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const folderDate = formatToFolderDate(date);
      days.push({
        date: day,
        folderDate,
        hasImages: availableDates.has(folderDate),
        isToday: folderDate === formatToFolderDate(today)
      });
    }

    return days;
  };

  // Load available dates
  const loadAvailableDates = async () => {
    try {
      const data = await s3.listObjectsV2({
        Bucket: S3_BUCKET,
        Prefix: FOLDER_PREFIX,
        Delimiter: '/'
      }).promise();

      const dates = new Set(
        data.CommonPrefixes
          ?.map(prefix => prefix.Prefix.split('/')[1])
          .filter(date => /^\d{2}_\d{2}_\d{2}$/.test(date)) || []
      );

      setAvailableDates(dates);
    } catch (error) {
      console.error('Error loading dates:', error);
    } finally {
      setLoading(false);
    }
  };

  // Load images for date
  const loadImagesForDate = async (date) => {
    try {
      setLoading(true);
      const fullPrefix = `${FOLDER_PREFIX}${date}/`;
      
      const data = await s3.listObjectsV2({
        Bucket: S3_BUCKET,
        Prefix: fullPrefix
      }).promise();

      if (data.Contents) {
        const imageList = data.Contents
          .filter(item => /\.(jpg|jpeg|png|gif)$/i.test(item.Key))
          .map(item => ({
            key: item.Key,
            url: `https://${S3_BUCKET}.s3.amazonaws.com/${encodeURIComponent(item.Key)}`,
            size: item.Size,
            lastModified: item.LastModified,
            name: item.Key.split('/').pop()
          }))
          .sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));

        setImages(imageList);
        setFilteredImages(imageList);
      } else {
        setImages([]);
        setFilteredImages([]);
      }
    } catch (error) {
      console.error('Error loading images:', error);
      setImages([]);
      setFilteredImages([]);
    } finally {
      setLoading(false);
    }
  };

  // Image handlers
  const handleImageClick = (image) => {
    setHiddenBoxes(prev => {
      const newBoxes = new Set(prev);
      newBoxes.add(image.key);
      saveToLocalStorage(newBoxes); // Save immediately
      return newBoxes;
    });
    setSelectedImage(image);
    setZoomLevel(1);
  };

  const formatDate = (dateStr) => {
    const [yy, mm, dd] = dateStr.split('_');
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return `${monthNames[parseInt(mm)-1]} ${dd}, 20${yy}`;
  };

  // const handleZoom = (delta) => {
  //   setZoomLevel(prev => Math.max(0.5, Math.min(3, prev + delta)));
  // };

  const navigateImage = (direction) => {
    if (!selectedImage) return;
    const currentIndex = filteredImages.findIndex(img => img.key === selectedImage.key);
    const newIndex = currentIndex + direction;
    
    if (newIndex >= 0 && newIndex < filteredImages.length) {
      setHiddenBoxes(prev => {
        const newBoxes = new Set(prev);
        newBoxes.add(filteredImages[newIndex].key);
        return newBoxes;
      });
      setSelectedImage(filteredImages[newIndex]);
      setZoomLevel(1);
    }
  };
  const handleZoom = (delta) => {
    setZoomLevel(prev => {
      const newZoom = prev + delta;
      // Allow much higher zoom levels
      return Math.max(0.1, newZoom);
    });
  };

  // Effects
  useEffect(() => {
    loadAvailableDates();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      loadImagesForDate(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!images) return;
    const filtered = images.filter(image => 
      image.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredImages(filtered);
  }, [searchTerm, images]);

  // Calendar Component
 
  
  return (
    <div className="min-h-screen w-full p-2 sm:p-4 bg-gray-900" onClick={handleOutsideClick}>
      <div className="max-w-[1400px] mx-auto space-y-4 lg:space-y-0 lg:flex lg:gap-4">
        {/* Main content */}
        <div className="flex-1 order-2 lg:order-1">
          <Card className="bg-gray-800 border-gray-700">
            <CardHeader className="p-3 sm:p-4">
              <div className="flex items-center justify-between">
                <CardTitle className="text-base sm:text-xl text-white">
                  {selectedDate ? formatDate(selectedDate) : 'Select Date'}
                </CardTitle>
              </div>

              {/* <div className="relative mt-3">
                <Input
                  placeholder="Search images..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-10 bg-gray-700 border-gray-600 text-white"
                />
                <Search className="h-4 w-4 absolute left-3 top-3 text-gray-400" />
              </div> */}
            </CardHeader>

            <CardContent className="h-[calc(100vh-320px)] lg:h-[calc(100vh-240px)] overflow-y-auto custom-calendar-scrollbar">
              {loading ? (
                <div className="flex items-center justify-center p-8">
                  <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
                </div>
              ) : (
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 sm:gap-4 p-2 sm:p-4">
                  {filteredImages.map((image, index) => (
                    <div key={image.key} className="group relative aspect-square overflow-hidden rounded-lg bg-gray-700 hover:ring-2 hover:ring-blue-500 transition-all">
                      {index === 0 && (
                        <div className="absolute top-2 right-2 z-20 bg-green-500 text-white px-2 py-1 rounded-md text-xs font-bold">
                          NEW
                        </div>
                      )}
                      <div 
                        className="relative w-full h-full cursor-pointer" 
                        onClick={(e) => {
                          if (e.ctrlKey || e.metaKey) {
                            toggleBoundingBox(image.key, e);
                          } else {
                            handleImageClick(image);
                          }
                        }}
                      >
                        <img
                          src={image.url}
                          alt={image.name}
                          className="object-cover w-full h-full transition-transform group-hover:scale-105"
                        />
                        <div className={`absolute inset-0 border-4 transition-colors duration-200
                          ${!hiddenBoxes.has(image.key) ? 'border-green-500' : 'border-transparent'}`}
                        />
                        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-40 transition-opacity" />
                        <div className="absolute bottom-0 left-0 right-0 p-2 bg-black bg-opacity-75 transform translate-y-full group-hover:translate-y-0 transition-transform">
                          <p className="text-xs sm:text-sm text-white truncate">{image.name}</p>
                          <p className="text-[10px] sm:text-xs text-gray-300">
                            {new Date(image.lastModified).toLocaleTimeString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {selectedDate && filteredImages.length === 0 && !loading && (
                <div className="text-center py-8 text-gray-400">
                  No images found{searchTerm ? ` matching "${searchTerm}"` : ''}
                </div>
              )}
            </CardContent>
          </Card>
        </div>

        {/* Calendar sidebar */}
        <div className="lg:w-80 order-1 lg:order-2">
          <Card className="bg-gray-800 border-gray-700">
            <CardHeader className="p-3 sm:p-4">
              <div className="flex items-center justify-between mb-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1))}
                  className="text-gray-300 hover:text-white"
                >
                  <ChevronLeft className="h-4 w-4" />
                </Button>
                <span className="text-sm sm:text-lg font-medium text-white">
                  {MONTHS[currentMonth.getMonth()]} {currentMonth.getFullYear()}
                </span>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1))}
                  className="text-gray-300 hover:text-white"
                >
                  <ChevronRight className="h-4 w-4" />
                </Button>
              </div>
            </CardHeader>
            <CardContent className="p-2 sm:p-4">
              <div className="grid grid-cols-7 gap-1 mb-2">
                {DAYS_OF_WEEK.map(day => (
                  <div key={day} className="text-center text-xs sm:text-sm font-medium text-gray-300">
                    {day.slice(0, 1)}
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-7 gap-1">
                {getCalendarDays().map((day, idx) => (
                  <div key={idx} className="aspect-square">
                    {day && (
                      <button
                        onClick={() => day.hasImages && setSelectedDate(day.folderDate)}
                        className={`w-full h-full flex items-center justify-center rounded-full text-sm sm:text-base font-medium
                          ${day.hasImages ? 'hover:bg-blue-500/20 cursor-pointer' : 'text-gray-500 cursor-default'}
                          ${day.isToday ? 'ring-2 ring-green-500 text-green-400' : ''}
                          ${day.folderDate === selectedDate ? 'bg-blue-500 text-white' : ''}
                          ${day.hasImages && !day.isToday && day.folderDate !== selectedDate ? 'text-blue-400' : ''}`}
                      >
                        {day.date}
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Image Modal */}
      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setSelectedImage(null);
            }
          }}
        >
          <div className="relative w-full h-full flex items-center justify-center p-4 sm:p-8">
            {/* Navigation Buttons */}
            <button
              className="absolute left-2 sm:left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 p-1 sm:p-2 rounded-full hover:bg-gray-700 z-50"
              onClick={(e) => {
                e.stopPropagation();
                navigateImage(-1);
              }}
              disabled={filteredImages.indexOf(selectedImage) === 0}
            >
              <ArrowLeft className="h-4 w-4 sm:h-6 sm:w-6 text-white" />
            </button>

            <button
              className="absolute right-2 sm:right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 p-1 sm:p-2 rounded-full hover:bg-gray-700 z-50"
              onClick={(e) => {
                e.stopPropagation();
                navigateImage(1);
              }}
              disabled={filteredImages.indexOf(selectedImage) === filteredImages.length - 1}
            >
              <ArrowRight className="h-4 w-4 sm:h-6 sm:w-6 text-white" />
            </button>

            {/* Zoom Controls */}
            <div className="absolute top-2 sm:top-4 right-2 sm:right-4 flex gap-1 sm:gap-2 z-50">
              <Button 
                variant="outline" 
                size="icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleZoom(-0.5);
                }}
                className="bg-gray-800 hover:bg-gray-700 h-8 w-8 sm:h-10 sm:w-10"
              >
                <ZoomOut className="h-3 w-3 sm:h-4 sm:w-4 text-white" />
              </Button>
              <Button 
                variant="outline" 
                size="icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handleZoom(0.5);
                }}
                className="bg-gray-800 hover:bg-gray-700 h-8 w-8 sm:h-10 sm:w-10"
              >
                <ZoomIn className="h-3 w-3 sm:h-4 sm:w-4 text-white" />
              </Button>
              <Button 
                variant="outline" 
                size="icon"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedImage(null);
                }}
                className="bg-gray-800 hover:bg-gray-700 h-8 w-8 sm:h-10 sm:w-10"
              >
                <X className="h-3 w-3 sm:h-4 sm:w-4 text-white" />
              </Button>
            </div>

            {/* Image Container */}
            <div 
              className="max-w-[95vw] max-h-[80vh] sm:max-w-[90vw] sm:max-h-[90vh]"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={selectedImage.url}
                alt={selectedImage.name}
                className="max-w-none transition-transform duration-200"
                style={{ 
                  transform: `scale(${zoomLevel})`,
                  transformOrigin: 'center',
                  cursor: zoomLevel > 1 ? 'move' : 'default'
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HistoryView;