// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId: 'GTM-543R665R',
  dataLayer: {
    gtag_id: 'G-B5NZJGWFEC' // Add your GA4 Measurement ID
  }
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Send web vitals to GA4
reportWebVitals(({ id, name, value }) => {
  window.dataLayer?.push({
    event: 'web-vitals',
    event_category: 'Web Vitals',
    event_action: name,
    event_value: Math.round(name === 'CLS' ? value * 1000 : value),
    event_label: id,
  });
});