import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { FileText, Download, Filter, Eye, X ,Clock,Lock} from 'lucide-react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import AWS from 'aws-sdk';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader,
  DialogTitle,
  DialogDescription
} from '../ui/dialog';

AWS.config.update({
  region: 'us-east-1',
  accessKeyId: 'AKIA3LET6GOC2WEU2DWR',
  secretAccessKey: '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',
  signatureVersion: 'v4',
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();

const ReportsView = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filterDate, setFilterDate] = useState('');
  const [filteredReports, setFilteredReports] = useState([]);

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
  };

  const formatTime = (date) => {
    const d = new Date(date);
    return `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}`;
  };

  const handleFilterClick = () => {
    setShowFilter(true);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
    setFilterDate('');
    setFilteredReports(reports);
  };

  const handleFilterKeyDown = (e) => {
    if (e.key === 'Enter') {
      const filtered = reports.filter(report => formatDate(report.date).includes(filterDate));
      setFilteredReports(filtered);
      setShowFilter(false);
    }
  };

  // Previous fetchReports function with a small modification
  const fetchReports = async () => {
    try {
      setLoading(true);
      const params = {
        TableName: 'saudican',
      };

      const result = await dynamoDB.scan(params).promise();
      
      if (result.Items) {
        const formattedReports = result.Items.map(item => ({
          id: item.id,
          name: 'Daily Production Report',
          date: item.timestamp || new Date().toISOString(),
          type: 'Daily',
          size: '245 KB',
          details: {
            line: 'Aerosol component presses',
            shift: '1',
            tops: {
              productCode: 'ABC123',
              description: 'Standard Top Component',
              totalProduction: item.total_count || 0,
              scrap: item.defect_count || 0,
              netProduction: item.good_count || 0,
              type: 'plain',
              compound: 'orange'
            }
          }
        }));

        formattedReports.sort((a, b) => new Date(b.date) - new Date(a.date));
        setReports(formattedReports);
        setFilteredReports(formattedReports);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching reports:', error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchReports();
    const interval = setInterval(fetchReports, 300000);
    return () => clearInterval(interval);
  }, []);

  const drawCheckmark = (page, x, y, checked = false) => {
    page.drawRectangle({
      x,
      y,
      width: 10,
      height: 10,
      borderColor: rgb(0, 0, 0),
      borderWidth: 1,
      color: rgb(1, 1, 1, 0)
    });

    if (checked) {
      page.drawLine({
        start: { x: x + 2, y: y + 5 },
        end: { x: x + 4, y: y + 2 },
        thickness: 1.5,
        color: rgb(0, 0.5, 0)
      });
      page.drawLine({
        start: { x: x + 4, y: y + 2 },
        end: { x: x + 8, y: y + 8 },
        thickness: 1.5,
        color: rgb(0, 0.5, 0)
      });
    }
  };

  const generatePDF = async (report) => {
    const pdfDoc = await PDFDocument.create();
    const fonts = {
      helvetica: await pdfDoc.embedFont(StandardFonts.Helvetica),
      helveticaBold: await pdfDoc.embedFont(StandardFonts.HelveticaBold),
      timesRoman: await pdfDoc.embedFont(StandardFonts.TimesRoman)
    };
    
    const page = pdfDoc.addPage([595.28, 841.89]);
    const { width, height } = page.getSize();

    try {
      const logoResponse = await fetch('/saudicanlogo.svg');
      const svgString = await logoResponse.text();
      const container = document.createElement('div');
      container.innerHTML = svgString;
      const svgElement = container.querySelector('svg');
      
      svgElement.setAttribute('width', '400');
      svgElement.setAttribute('height', '270');
      
      const svgUrl = URL.createObjectURL(new Blob([svgElement.outerHTML], { type: 'image/svg+xml' }));
      const img = new Image();
      
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = svgUrl;
      });
      
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      
      const pngData = canvas.toDataURL('image/png').split(',')[1];
      const logoImage = await pdfDoc.embedPng(Uint8Array.from(atob(pngData), c => c.charCodeAt(0)));
      
      const logoDims = logoImage.scale(0.4);
      page.drawImage(logoImage, {
        x: 40,
        y: height - 70,
        width: logoDims.width,
        height: logoDims.height,
      });
      
      URL.revokeObjectURL(svgUrl);
    } catch (error) {
      console.error('Logo loading error:', error);
    }

    const reportDate = new Date(report.date);
    const formattedDate = formatDate(reportDate);
    const formattedTime = formatTime(reportDate);

    const headerY = height - 30;
    page.drawText('PRO (TN)-2.FM:05', {
      x: width - 250,
      y: headerY,
      size: 10,
      font: fonts.helvetica
    });

    page.drawText(`${formattedDate} ${formattedTime}`, {
      x: width - 150,
      y: headerY,
      size: 10,
      font: fonts.helvetica
    });

    page.drawText('DAILY PRODUCTION REPORT', {
      x: width / 2 - 100,
      y: height - 100,
      size: 14,
      font: fonts.helveticaBold
    });

    const infoY = height - 140;
    page.drawText('Line:', {
      x: 40,
      y: infoY,
      size: 12,
      font: fonts.helvetica
    });

    const lineText = report.details.line;
    const lineTextWidth = fonts.helvetica.widthOfTextAtSize(lineText, 12);
    
    page.drawText(lineText, {
      x: 80,
      y: infoY,
      size: 12,
      font: fonts.helvetica
    });

    page.drawLine({
      start: { x: 80, y: infoY - 2 },
      end: { x: 80 + lineTextWidth, y: infoY - 2 },
      thickness: 0.5,
      color: rgb(0, 0, 0)
    });

    page.drawText(`Date: ${formattedDate}`, {
      x: width - 200,
      y: infoY,
      size: 12,
      font: fonts.helvetica
    });

    page.drawText(`Shift: ${report.details.shift}`, {
      x: width - 200,
      y: infoY - 20,
      size: 12,
      font: fonts.helvetica
    });
    const tableTop = infoY - 140;
    const tableWidth = width - 80;
    const columns = [
      { width: tableWidth * 0.4, title: 'Product Description' },
      { width: tableWidth * 0.2, title: 'Total Production', align: 'center' },
      { width: tableWidth * 0.2, title: 'Defects', align: 'center' },
      { width: tableWidth * 0.2, title: 'Good Parts', align: 'center' }
    ];

    page.drawText('TOPS', {
      x: 40,
      y: tableTop + 40,
      size: 12,
      font: fonts.helveticaBold
    });

    page.drawText('Product code no.:', {
      x: 40,
      y: tableTop + 15,
      size: 10,
      font: fonts.helvetica
    });

    page.drawText(report.details.tops.productCode, {
      x: 130,
      y: tableTop + 15,
      size: 10,
      font: fonts.helvetica
    });

    page.drawRectangle({
      x: 40,
      y: tableTop,
      width: tableWidth,
      height: 25,
      color: rgb(0.95, 0.95, 0.95)
    });

    let xOffset = 40;
    columns.forEach((column) => {
      page.drawLine({
        start: { x: xOffset, y: tableTop },
        end: { x: xOffset, y: tableTop - 50 },
        thickness: 1,
        color: rgb(0, 0, 0)
      });

      const textWidth = fonts.helveticaBold.widthOfTextAtSize(column.title, 10);
      const textX = column.align === 'center' 
        ? xOffset + (column.width - textWidth) / 2 
        : xOffset + 5;

      page.drawText(column.title, {
        x: textX,
        y: tableTop - 17,
        size: 10,
        font: fonts.helveticaBold
      });

      xOffset += column.width;
    });

    page.drawLine({
      start: { x: 40 + tableWidth, y: tableTop },
      end: { x: 40 + tableWidth, y: tableTop - 50 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    const dataY = tableTop - 25;
    const data = [
      report.details.tops.description,
      report.details.tops.totalProduction.toString(),
      report.details.tops.scrap.toString(),
      report.details.tops.netProduction.toString()
    ];

    xOffset = 40;
    columns.forEach((column, index) => {
      const textWidth = fonts.helvetica.widthOfTextAtSize(data[index], 10);
      const textX = column.align === 'center'
        ? xOffset + (column.width - textWidth) / 2
        : xOffset + 5;

      page.drawText(data[index], {
        x: textX,
        y: dataY - 17,
        size: 10,
        font: fonts.helvetica
      });

      xOffset += column.width;
    });

    page.drawText('Plain:', {
      x: 40,
      y: tableTop - 80,
      size: 10,
      font: fonts.helvetica
    });

    drawCheckmark(page, 80, tableTop - 83, report.details.tops.type === 'plain');

    page.drawText('Compound OP 649 LV Orange', {
      x: 40,
      y: tableTop - 140,
      size: 10,
      font: fonts.helvetica
    });

    drawCheckmark(page, 200, tableTop - 143, report.details.tops.compound === 'orange');

    page.drawText('Compound DS 3069 N Grey', {
      x: 40,
      y: tableTop - 160,
      size: 10,
      font: fonts.helvetica
    });

    drawCheckmark(page, 200, tableTop - 163, report.details.tops.compound === 'grey');

    page.drawText('Signature:', {
      x: 40,
      y: 50,
      size: 12,
      font: fonts.helvetica
    });

    page.drawLine({
      start: { x: 100, y: 50 },
      end: { x: 250, y: 50 },
      thickness: 1,
      color: rgb(0, 0, 0)
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  };

  const canAccessReport = (dateString) => {
    const now = new Date();
    const reportDate = new Date(dateString);
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const reportDay = new Date(reportDate.getFullYear(), reportDate.getMonth(), reportDate.getDate());
    
    // If it's not today's report, allow access
    if (reportDay < today) {
      return true;
    }
    
    // If it's today's report, check if it's after 6 PM
    if (reportDay.getTime() === today.getTime()) {
      return now.getHours() >= 18;
    }
  
    return true;
  };
  
  const getReportStatus = (dateString) => {
    const now = new Date();
    const reportDate = new Date(dateString);
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const reportDay = new Date(reportDate.getFullYear(), reportDate.getMonth(), reportDate.getDate());
  
    // Only show countdown for today's report
    if (reportDay.getTime() === today.getTime() && now.getHours() < 18) {
      const endTime = new Date(today);
      endTime.setHours(18, 0, 0, 0);
      const diff = endTime - now;
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      return {
        locked: true,
        message: `Today's report will be available after 6 PM (${hours}h ${minutes}m remaining)`
      };
    }
  
    return {
      locked: false,
      message: 'Report available'
    };
  };
  
  const ProductionReportCard = ({ report }) => {
    const reportStatus = getReportStatus(report.date);
    const isAccessible = canAccessReport(report.date);
  
    return (
      <Card className="bg-gray-800 border-gray-700 mb-4">
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle className="text-white flex items-center gap-2">
            <FileText className="h-5 w-5 text-cyan-400" />
            Report for {formatDate(report.date)}
          </CardTitle>
          <div className="flex gap-2">
            {isAccessible ? (
              <>
                <button 
                  className="p-2 hover:bg-gray-600 rounded-lg transition-colors"
                  title="View Report"
                  onClick={() => handleView(report)}
                >
                  <Eye className="h-5 w-5 text-cyan-400" />
                </button>
                <button 
                  className="p-2 hover:bg-gray-600 rounded-lg transition-colors"
                  title="Download Report"
                  onClick={() => handleDownload(report)}
                >
                  <Download className="h-5 w-5 text-cyan-400" />
                </button>
              </>
            ) : (
              <div className="flex items-center gap-2 px-3 py-1 bg-gray-700/50 rounded-lg">
                <Clock className="h-4 w-4 text-amber-400" />
                <span className="text-sm text-amber-400">{reportStatus.message}</span>
              </div>
            )}
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-4 text-white">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-gray-400">Date: {formatDate(report.date)}</p>
                <p className="text-gray-400">Line: {report.details.line}</p>
              </div>
              <div>
                <p className="text-gray-400">Shift: {report.details.shift}</p>
                <p className="text-gray-400">ID: {report.id}</p>
              </div>
            </div>
            
            <div className="mt-4">
              <h4 className="font-medium mb-2">Production Data</h4>
              <div className="bg-gray-700/50 p-4 rounded-lg">
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <p className="text-sm text-gray-400">Total Production</p>
                    <p className="text-lg">{report.details.tops.totalProduction}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Defects</p>
                    <p className="text-lg text-rose-400">{report.details.tops.scrap}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Good Parts</p>
                    <p className="text-lg text-emerald-400">{report.details.tops.netProduction}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };
  
  // Update the handler functions
  const handleView = async (report) => {
    if (!canAccessReport(report.date)) {
      return;
    }
    setCurrentReport(report);
    const url = await generatePDF(report);
    setPdfUrl(url);
    setIsViewerOpen(true);
  };
  
  const handleDownload = async (report) => {
    if (!canAccessReport(report.date)) {
      return;
    }
    const url = await generatePDF(report);
    const link = document.createElement('a');
    link.href = url;
    link.download = `Production-Report-${formatDate(report.date)}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
 
  return (
    <div className="flex flex-col h-screen p-6">
      {/* Fixed Header Section */}
      <div className="flex justify-between items-center mb-6 sticky top-0 z-10 bg-gray-900/95 backdrop-blur-sm py-4">
        <h1 className="text-2xl font-bold text-white">Production Reports</h1>
        <div className="flex items-center gap-4">
          {showFilter && (
            <div className="flex items-center bg-gray-700 rounded-lg px-3 py-2">
              <input
                type="text"
                placeholder="Enter date (DD/MM/YYYY)"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
                onKeyDown={handleFilterKeyDown}
                className="bg-transparent border-none text-white placeholder-gray-400 focus:outline-none"
              />
              <button
                onClick={handleFilterClose}
                className="ml-2 text-gray-400 hover:text-white"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          )}
          <button 
            className="flex items-center gap-2 px-4 py-2 bg-cyan-500 text-white rounded-lg hover:bg-cyan-600 transition-all duration-200"
            onClick={fetchReports}
          >
            <span>Refresh Reports</span>
          </button>
          <button 
            className="flex items-center gap-2 px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-all duration-200"
            onClick={handleFilterClick}
          >
            <Filter className="h-4 w-4" />
            Filter
          </button>
        </div>
      </div>

      {/* Scrollable Content Area */}
      <div 
        className="flex-1 overflow-y-auto scroll-smooth pr-2 -mr-2 space-y-4"
        style={{
          scrollbarWidth: 'thin',
          scrollbarColor: '#1f2937 transparent'
        }}
      >
        {loading ? (
          <div className="flex justify-center items-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-cyan-400" />
          </div>
        ) : filteredReports.length === 0 ? (
          <div className="text-center py-12 text-gray-400">
            No reports available
          </div>
        ) : (
          <div className="space-y-4 pb-6">
            {filteredReports.map((report, index) => (
              <div
                key={report.id}
                className="transform transition-all duration-300 hover:translate-y-[-2px] hover:shadow-lg"
              >
                <ProductionReportCard report={report} />
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Custom Scrollbar Styles */}
      <style jsx global>{`
        /* Webkit (Chrome, Safari, etc.) */
        ::-webkit-scrollbar {
          width: 8px;
        }

        ::-webkit-scrollbar-track {
          background: transparent;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #374151;
          border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background-color: #4b5563;
        }

        /* Firefox */
        * {
          scrollbar-width: thin;
          scrollbar-color: #374151 transparent;
        }

        /* For smooth scrolling on the entire page */
        html {
          scroll-behavior: smooth;
        }
      `}</style>

      {/* Modal remains unchanged */}
      <Dialog open={isViewerOpen} onOpenChange={setIsViewerOpen}>
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>
              Production Report - {currentReport ? formatDate(currentReport.date) : ''}
            </DialogTitle>
            <DialogDescription>
              Daily production report details
            </DialogDescription>
          </DialogHeader>
          {pdfUrl && (
            <div className="overflow-hidden rounded-lg">
              <iframe
                src={pdfUrl}
                className="w-full h-[80vh]"
                title="PDF Viewer"
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReportsView;