// import React, { useState, useEffect } from 'react';
// import { Menu, X, LayoutDashboard, History, FileText, LogOut, User } from 'lucide-react';
// import { cn } from '../../lib/utils';

// const menuItems = [
//   {
//     icon: <LayoutDashboard className="h-5 w-5" />,
//     title: 'Nommas AI Brain',
//     path: 'dashboard'
//   },
//   {
//     icon: <History className="h-5 w-5" />,
//     title: 'Defect Library',
//     path: 'historie'
//   },
//   {
//     icon: <FileText className="h-5 w-5" />,
//     title: 'Reports',
//     path: 'reports'
//   }
// ];

// const Layout = ({ children, activePage, onPageChange, onLogout, user }) => {
//   const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
//   const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

//   // Close menus on unmount
//   useEffect(() => {
//     return () => {
//       setIsMobileSidebarOpen(false);
//       setIsUserMenuOpen(false);
//     };
//   }, []);

//   const handleMenuClick = (path) => {
//     onPageChange(path);
//     setIsMobileSidebarOpen(false);
//   };

//   const handleLogoutClick = () => {
//     if (onLogout) {
//       onLogout();
//       setIsUserMenuOpen(false);
//       setIsMobileSidebarOpen(false);
//     }
//   };

//   const SidebarContent = () => (
//     <div className="flex flex-col h-full">
//       <nav className="flex-1 px-3 py-4">
//         {menuItems.map((item) => (
//           <button
//             key={item.path}
//             onClick={() => handleMenuClick(item.path)}
//             className={cn(
//               "w-full flex items-center px-3 py-2 rounded-lg mb-1 text-sm",
//               "transition-colors duration-200 group",
//               activePage === item.path 
//                 ? "bg-cyan-500/10 text-cyan-500" 
//                 : "text-gray-400 hover:bg-gray-800 hover:text-white"
//             )}
//           >
//             <span className="flex items-center gap-3">
//               {item.icon}
//               {item.title}
//             </span>
//           </button>
//         ))}
//       </nav>
      
//       <div className="px-3 py-4 border-t border-gray-800">
//         <div className="text-center mb-4">
//           <p className="text-xs text-gray-500">Licensed by Nommas</p>
//         </div>
//         <button
//           onClick={handleLogoutClick}
//           className="w-full flex items-center justify-center gap-2 px-3 py-2 rounded-lg text-sm text-red-400 hover:bg-red-500/10 hover:text-red-300 transition-colors duration-200"
//         >
//           <LogOut className="h-4 w-4" />
//           <span>Sign out</span>
//         </button>
//       </div>
//     </div>
//   );


//   const UserMenu = () => (
//     <div className="relative">
//       <button
//         onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
//         className="flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm text-gray-400 hover:text-white transition-colors duration-200"
//       >
//         <div className="bg-gradient-to-r from-cyan-500 to-blue-500 p-1 rounded-lg">
//           <User className="h-5 w-5 text-white" />
//         </div>
//         <span className="hidden md:inline">{user?.username || 'User'}</span>
//       </button>
  
//       {isUserMenuOpen && (
//         <div className="absolute top-full right-0 mt-2 w-48 bg-gray-900 rounded-lg shadow-lg border border-gray-700 overflow-hidden">
//           <div className="px-4 py-3 border-b border-gray-700">
//             <p className="text-sm font-medium text-white">{user?.username}</p>
//           </div>
//           <button
//             onClick={handleLogoutClick}
//             className="w-full flex items-center gap-2 px-4 py-2 text-sm text-red-400 hover:bg-red-500/10 hover:text-red-300 transition-colors duration-200"
//           >
//             <LogOut className="h-4 w-4" />
//             <span>Sign out</span>
//           </button>
//         </div>
//       )}
//     </div>
//   );

//   return (
//     <div className="min-h-screen flex flex-col md:flex-row bg-[#0F1216]">
//       {/* Desktop Sidebar */}
//       <div className="hidden md:flex flex-col w-64 bg-[#171B21] border-r border-gray-800">
//         <div className="h-16 flex items-center px-4 border-b border-gray-800">
//           <div className="flex items-center space-x-3">
//             <img 
//               src="/logo.png" 
//               alt="Nommas AI" 
//               className="h-12 w-auto" 
//               style={{ 
//                 objectFit: 'contain',
//                 maxWidth: '200px'
//               }} 
//             />
//           </div>
//         </div>
//         <SidebarContent />
//       </div>

//       {/* Mobile Sidebar */}
//       <div className={cn(
//         "fixed inset-y-0 left-0 w-64 bg-[#171B21] transition-all duration-300 ease-in-out z-50 md:hidden",
//         isMobileSidebarOpen ? "translate-x-0" : "-translate-x-full"
//       )}>
//         <button
//           onClick={() => setIsMobileSidebarOpen(false)}
//           className="absolute right-4 top-5 text-gray-400 hover:text-white"
//         >
//           <X className="h-6 w-6" />
//         </button>
//         <div className="h-16 flex items-center px-4 border-b border-gray-800">
//           <div className="flex items-center space-x-3">
//             <img 
//               src="/logo.png" 
//               alt="Nommas AI" 
//               className="h-12 w-auto" 
//               style={{ 
//                 objectFit: 'contain',
//                 maxWidth: '200px'
//               }} 
//             />
//           </div>
//         </div>
//         <SidebarContent />
//       </div>

//       {/* Main Content */}
//       <div className="flex-1 flex flex-col">
//         {/* Header */}
//         <div className="h-16 bg-[#171B21] border-b border-gray-800 flex items-center px-4 sticky top-0 z-10">
//           <div className="flex items-center justify-between w-full">
//             <div className="flex items-center gap-4">
//               <button
//                 onClick={() => setIsMobileSidebarOpen(true)}
//                 className="text-gray-400 hover:text-white p-1.5 md:hidden"
//               >
//                 <Menu className="h-5 w-5" />
//               </button>
//               <span className="bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent text-lg font-medium">
//                 {menuItems.find(item => item.path === activePage)?.title}
//               </span>
//             </div>
//             <UserMenu />
//           </div>
//         </div>

//         {/* Main Content Area */}
//         <div className="flex-1 relative overflow-auto md:overflow-hidden">
//           <div className="p-6 min-h-full">
//             {children}
//           </div>
//         </div>
//       </div>

//       {/* Mobile Sidebar Overlay */}
//       {isMobileSidebarOpen && (
//         <div 
//           className="fixed inset-0 bg-black/50 z-40 md:hidden"
//           onClick={() => setIsMobileSidebarOpen(false)}
//         />
//       )}

//       {/* User Menu Overlay */}
//       {isUserMenuOpen && (
//         <div 
//           className="fixed inset-0 z-30"
//           onClick={() => setIsUserMenuOpen(false)}
//         />
//       )}
//     </div>
//   );
// };

// export default Layout;
import React, { useState, useEffect } from 'react';
import { Menu, X, LayoutDashboard, History, FileText, LogOut } from 'lucide-react';
import { cn } from '../../lib/utils';

const menuItems = [
  {
    icon: <LayoutDashboard className="h-5 w-5" />,
    title: 'Nommas AI Brain',
    path: 'dashboard'
  },
  {
    icon: <History className="h-5 w-5" />,
    title: 'Defect Library',
    path: 'historie'
  },
  {
    icon: <FileText className="h-5 w-5" />,
    title: 'Reports',
    path: 'reports'
  }
];

const Layout = ({ children, activePage, onPageChange, onLogout }) => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  useEffect(() => {
    return () => {
      setIsMobileSidebarOpen(false);
    };
  }, []);

  const handleMenuClick = (path) => {
    onPageChange(path);
    setIsMobileSidebarOpen(false);
  };

  const handleLogoutClick = () => {
    if (onLogout) {
      onLogout();
      setIsMobileSidebarOpen(false);
    }
  };

  const SidebarContent = () => (
    <div className="flex flex-col h-full">
      <nav className="flex-1 px-3 py-4">
        {menuItems.map((item) => (
          <button
            key={item.path}
            onClick={() => handleMenuClick(item.path)}
            className={cn(
              "w-full flex items-center px-3 py-2 rounded-lg mb-1 text-sm",
              "transition-colors duration-200 group",
              activePage === item.path 
                ? "bg-cyan-500/10 text-cyan-500" 
                : "text-gray-400 hover:bg-gray-800 hover:text-white"
            )}
          >
            <span className="flex items-center gap-3">
              {item.icon}
              {item.title}
            </span>
          </button>
        ))}
      </nav>
      
      <div className="px-3 py-4 border-t border-gray-800">
        <div className="text-center mb-4">
          <p className="text-xs text-gray-500">Licensed by Nommas</p>
        </div>
        <button
          onClick={handleLogoutClick}
          className="w-full flex items-center justify-center gap-2 px-3 py-2 rounded-lg text-sm text-red-400 hover:bg-red-500/10 hover:text-red-300 transition-colors duration-200"
        >
          <LogOut className="h-4 w-4" />
          <span>Sign out</span>
        </button>
      </div>
    </div>
  );

  return (
    <div className="h-screen flex flex-col md:flex-row bg-[#0F1216] overflow-hidden">
      <div className="hidden md:flex flex-col w-64 bg-[#171B21] border-r border-gray-800">
        <div className="h-16 flex items-center px-4 border-b border-gray-800">
          <div className="flex items-center space-x-3">
            <img 
              src="/logo.png" 
              alt="Nommas AI" 
              className="h-12 w-auto" 
              style={{ objectFit: 'contain', maxWidth: '200px' }} 
            />
          </div>
        </div>
        <SidebarContent />
      </div>

      <div className={cn(
        "fixed inset-y-0 left-0 w-64 bg-[#171B21] transition-all duration-300 ease-in-out z-50 md:hidden",
        isMobileSidebarOpen ? "translate-x-0" : "-translate-x-full"
      )}>
        <button
          onClick={() => setIsMobileSidebarOpen(false)}
          className="absolute right-4 top-5 text-gray-400 hover:text-white"
        >
          <X className="h-6 w-6" />
        </button>
        <div className="h-16 flex items-center px-4 border-b border-gray-800">
          <div className="flex items-center space-x-3">
            <img 
              src="/logo.png" 
              alt="Nommas AI" 
              className="h-12 w-auto" 
              style={{ objectFit: 'contain', maxWidth: '200px' }} 
            />
          </div>
        </div>
        <SidebarContent />
      </div>

      <div className="flex-1 flex flex-col min-h-screen md:min-h-0">
        <div className="h-16 flex-none bg-[#171B21] border-b border-gray-800 flex items-center px-4">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-4">
              <button
                onClick={() => setIsMobileSidebarOpen(true)}
                className="text-gray-400 hover:text-white p-1.5 md:hidden"
              >
                <Menu className="h-5 w-5" />
              </button>
              <span className="bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent text-lg font-medium">
                {menuItems.find(item => item.path === activePage)?.title}
              </span>
            </div>
            <button
              onClick={handleLogoutClick}
              className="md:hidden flex items-center gap-2 px-3 py-2 rounded-lg text-sm text-red-400 hover:bg-red-500/10 hover:text-red-300 transition-colors duration-200"
            >
              <LogOut className="h-4 w-4" />
              <span>Sign out</span>
            </button>
          </div>
        </div>

        <div className="flex-1 md:overflow-hidden overflow-auto">
          <div className="h-full p-6">
            {children}
          </div>
        </div>
      </div>

      {isMobileSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black/50 z-40 md:hidden"
          onClick={() => setIsMobileSidebarOpen(false)}
        />
      )}
    </div>
  );
};

export default Layout;